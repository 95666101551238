.nutrient_page .entity_page_boxtop {
    background-color: #269FDC;
    background-image: url(/public/static/img/nutientsbg.png);
    background-position: bottom right;
    background-repeat: no-repeat;
}
.Nutrient_row h4 {
       color: #303030;
    font-size: 22px;
    font-family: 'Brown Regular Regular';
    font-weight: 500;
    margin: 0 0 45px 0;
    padding: 7px 21px 7px;
    border-bottom: 2px solid #ececec;
}
.nutrient_btn_col {
    text-align: center;
}
.nutrient_btn_col .btntype3 {
    display: inline-block;
    padding: 8px 28px;
    box-shadow: 0px 0px 32px rgba(0,0,0,.3);
}
.nutrient_save_prference {
    text-align: center;
    width: 100%;
    margin-top: 45px;
}
.nutrient_save_prference .nutrient_save_prference .btntype3 {
    display: inline-block;
}
.nutrient_save_prference .btntype3 {
    display: inline-block;
    width: 100%;
    max-width: 320px;
    border-radius: 0;
}
.nutrient_save_prference.nutrient_save_prferencetop {
    margin-top: 15px;
    margin-bottom: 30px;
}
.range_slider.form {padding: 0px 20px 0px;position: relative;}
.input-range__track {
    height: 9px;
    background: #e6e4e4;
}
.input-range__track--active {background: #ef2859;}
.input-range__slider {
    width: 22px;
    height: 22px;
    background: #ef2859;
    border-color: red;
    top: -28px;
    box-shadow: 0px 2px 7px rgba(0,0,0,.2);
}
.input-range__label--min {
    font-size: 21px;
    top: -24px;
    color: #656e82;
    font-family: 'Brown Light';
    left: -1px;
    line-height: 1;
}
.input-range__label--max {
    font-size: 21px;
    top: -24px;
    color: #656e82;
    font-family: 'Brown Light';
    line-height: 1;
    right: 12px;
}
.input-range__slider-container span {
    color: #303030;
    font-size: 18px;
    font-family: 'Brown Regular Regular';
    position: static;
}
.input-range__slider-container {
    background: #fff;
    display: block;
    width: auto;
    padding: 6px 13px;
    transform: translate(-50%);
    top: 32px;
    box-shadow: 0px 1px 15px rgba(0,0,0,.08);
    border-radius: 5px;
}
.input-range__slider-container:before {content: '';display: block;border-left: 8px solid transparent;border-right: 8px solid transparent;border-bottom: 10px solid white;position: absolute;top: -9px;left: 50%;transform: translate(-50%);}
.range_slider .slider-ticks > div {
    display: none;
}
.range_slider .slider-ticks > div:first-child, .range_slider .slider-ticks > div:last-child {
    display: block;
    position: relative;
}
.range_slider .slider-ticks {
    position: absolute;
    width: 100%;
    top: -29px;
    left: 0;
}
.range_slider .slider-ticks div div:first-child {
    display: none;
}
.range_slider .slider-ticks div div {
    font-size: 19px !important;
    color: #656e82;
    font-family: 'Brown Light';
    line-height: 1;
    margin: 0 !important;
    display: none;
}
.range_slider .slider-ticks > div:first-child {left: 0;}
.range_slider .slider-ticks > div:last-child, .range_slider .slider-ticks > div:last-child div {
    right: 0;
}
.range_slider .slider-ticks > div:last-child div {
    right: 0 !important;
    margin: 0 !important;
    left: auto !important;
    position: absolute !important;
    width: auto !important;
}
.range_slider .slider-ticks > div:first-child div {
    width: auto !important;
    left: 0 !important;
    margin: 0 !important;
    right: auto !important;
    position: absolute !important;
}
.range_slider.form > div:nth-child(2) > div:first-child {
    background: #e6e4e4 !important;
    height: 10px !important;
}
.range_slider.form .slider-handles div {
    width: 22px !important;
    height: 22px !important;
    background: #269FDC !important;
    border-color: #269FDC;
    box-shadow: 0px 2px 7px rgba(0,0,0,.2) !important;
    top: -1px;
}
.range_slider .slider-tracks div {
    background: #269FDC !important;
    height: 10px !important;
}
.range_slider .slider-handles:before {content: attr(aria-valuenow);display: block;width: auto;}
.range_slider .slider-handles div:before {content: attr(aria-valuenow);display: block;width: auto;top: -27px;position: absolute;background: #fff;display: block;width: auto;padding: 0px 10px;box-shadow: 0px 1px 15px rgba(0,0,0,.08);border-radius: 5px;left: 50%;-webkit-transform: translate(-50%,0);-moz-transform: translate(-50%,0);-sm-transform: translate(-50%,0);transform: translate(-50%,0);}
.slider_hide {
    display: none;
}
.Nutrient_row {
    margin-bottom: 30px;
}




@media only screen and (min-width: 768px){
.nutrient_save_prferencetop {
display: none;
}
}


@media only screen and (max-width: 767px){
.nutrient_page .col-md-12 {
    padding: 0 !important;
}
.nutrient_page .inner_page_innersec {
    max-width: 100%;
}
.nutrient_page .entity_page_boxbtm {
    padding: 0;
    border-radius: 0 !important;
}
.nutrient_page .entity_page_boxbtminner {
    border-radius: 0;
}
.nutrient_page .entity_page_boxtop {
    border-radius: 0;
}
.nutrient_save_prference {
    margin-top: 11px;
}
.nutrient_page h1 {
    display: none !important;
}
.Nutrient_row {
    background: #fff;
    border-radius: 12px;
    box-shadow: 1px 5px 19px rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding-bottom: 37px;
}
}


@media only screen and (max-width: 480px){

.Nutrient_row .col-sm-12 {
    padding: 0;
}
.nutrient_save_prference .btntype3{
    max-width: 100%;
}
.Nutrient_row h4 {
    font-size: 20px;
}
}
