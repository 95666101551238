.ketocurated_page .entity_page_boxtop {
    background-color: #F0883A;
    background-image: url(/public/static/img/ketobgicon.png);
    background-position: bottom right;
    background-repeat: no-repeat;
}

.ketocurated_page .entity_page_boxbtminner .chose_sec label span:after {
    background: #F0883A !important;
}


@media only screen and (max-width: 767px){
.ketocurated_page .col-md-12 {
    padding: 0 !important;
}
.ketocurated_page .inner_page_innersec {
    max-width: 100%;
}
.ketocurated_page .entity_page_boxbtm {
    padding: 0;
    border-radius: 0 !important;
}
.ketocurated_page .entity_page_boxbtminner {
    border-radius: 0;
}
.ketocurated_page .entity_page_boxtop {
    border-radius: 0;
        background-size: 228px;
}
.ketocurated_page h1 {
    display: none !important;
}
.ketocurated_page .entity_page_boxtop {
    background-size: 253px;
}
/*.ketocurated_page .entity_page_boxtop h3 {
    max-width: 185px;
}*/
}
