.profile_sec .card {
    border: none;
    border-radius: 0;
}

    .profile_sec .card .card-header {
        padding: 0;
        border: none;
        background: #DCDCDC;
    }

        .profile_sec .card .card-header button.btn.btn-link {
            width: 100%;
            display: block;
            padding: 18px 40px;
            text-align: left;
            font-size: 20px;
            font-family: 'Brown Bold';
            color: #000 !important;
            border: none;
            border-bottom: 1px solid #c8c8c8;
            border-radius: 0;
        }

.profile_sec {
    padding: 40px 65px 66px;
    background: #ededed;
}

    .profile_sec #accordion {
        box-shadow: 0 15px 26px rgba(0, 0, 0,.13);
        border-radius: 15px;
        overflow: hidden;
    }

    .profile_sec .card .card-header button.btn.btn-link:hover, .profile_sec .card .card-header button.btn.btn-link:focus {
        text-decoration: none;
    }

.profile_top_sec .profile_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 2px 19px rgba(0,0,0,.1);
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.profile_top_sec h3.prfile_name {
    font-size: 24px;
    color: #333;
    position: relative;
    margin-bottom: 42px;
}

    .profile_top_sec h3.prfile_name:after {
        content: '';
        display: block;
        width: 50px;
        height: 5px;
        background: #ef2859;
        margin: 12px auto;
    }

.profile_sec .card .accordian_inner {
    padding: 0px;
    border: none;
    box-shadow: 0px 24px 33px rgba(0,0,0,.08) inset;
    background: #eeeeee;
}

    .profile_sec .card .accordian_inner .card-body {
        padding: 40px;
        background: #f7f7f7;
        border: 1px solid #d0d0d0;
    }

#myinformation_acc .btntype3 {
    margin: 35px auto 0;
    width: 201px;
    display: block;
}

.Mypreferences_acc_left h4 {
    font-size: 18px;
    font-family: 'Brown Regular Regular';
    font-weight: 500;
    margin-bottom: 4px;
}

.Mypreferences_acc_left p {
    display: block;
    line-height: 1.2;
    margin-bottom: 4px;
    font-family: 'Brown Light';
    font-size: 15px;
}

.Mypreferences_acc_left .Edit_pre {
    font-family: 'Brown Light';
    color: #ef2859;
    font-size: 16px;
    text-transform: uppercase;
}

.Mypreferences_acc_rowsingle {
    width: 100%;
    margin-bottom: 26px;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 2px 12px rgba(0,0,0,.1);
    border-radius: 10px;
    overflow: hidden;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

.Mypreferences_acc_row .Mypreferences_acc_rowsingle:last-child {
    margin-bottom: 0 !important;
}

.Mypreferences_acc_right {
    margin-left: auto;
}

.profile_sec .card .card-header button.btn.btn-link:after {
    content: '';
    display: block;
    width: 13px;
    height: 13px;
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    position: absolute;
    transition: all .3s;
    right: 43px;
    top: 24px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.profile_sec .card .card-header button.btn.btn-link:not(.collapsed):after {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    top: 24px;
}

.myfavorites_acc_rowsingle .col-sm-6 {
    padding: 0;
}

#myfavorites_acc {
    background: #fff;
}

.myfavorites_acc_rowsingle h3 {
    font-family: 'Brown Regular Regular';
    font-size: 22px;
    line-height: 1.3;
    padding-right: 0;
    margin: 0;
    cursor: pointer;
}

    .myfavorites_acc_rowsingle h3:hover {
        color: #ef2859;
    }

.myfavorites_acc_rowsingle {
    border-radius: 15px;
    margin-bottom: 30px;
    box-shadow: 0px 17px 27px rgba(0,0,0,.2);
    overflow: hidden;
}

.Mypreferences_acc_row .change_prferences_link {
    text-align: center;
    margin-top: 45px;
}

    .Mypreferences_acc_row .change_prferences_link a {
        color: #ef2859;
        font-size: 16px;
        font-family: 'Brown Bold';
    }

.profile_top_sec .profile_img input.form-control-file {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.profile_top_sec .profile_img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
}

.Mypreferences_acc_left {
    width: 50%;
    padding: 0 25px;
    margin-bottom: 15px;
}

.Mypreferences_acc_head {
    width: 100%;
    margin-bottom: 14px;
    padding: 12px 25px;
    background: #f7f7f7;
}

    .Mypreferences_acc_head h4 {
        float: left;
        font-size: 21px;
        color: #333;
        margin-bottom: 0;
    }

    .Mypreferences_acc_head a {
        float: right;
        color: #ee2859;
        font-size: 15px;
        font-family: 'Brown Light';
        margin-top: 2px;
    }

.myfavorites_acc_rowsingle .col-sm-6:first-child {
    padding: 0 5px 0px 20px;
}

.myfavorites_acc_rowsingle p {
    margin: 0;
    font-size: 16px;
    margin-top: 8px;
    color: #ef2859;
    font-weight: 600;
    font-family: 'Brown Bold';
    line-height: 1;
}

.myfavorites_acc_rowsingle .fav_sec {
    position: absolute;
    right: 6px;
    top: 12px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0,0,0,.3);
    line-height: 35px;
    display: block;
    text-align: center;
    cursor: pointer;
}

    .myfavorites_acc_rowsingle .fav_sec.active {
        background: #ef2859;
    }

    .myfavorites_acc_rowsingle .fav_sec img {
        width: 21px;
    }

.profile_form_error {
    position: absolute;
    top: 67px;
    width: calc(100% + 96px);
    left: -48px;
    background: #9e0b0f;
    color: #fff;
    padding: 10px 5px;
    font-size: 12px;
    text-align: center;
    line-height: 1;
}



@media only screen and (min-width: 768px) {
    .Profile_mobile_header {
        display: none;
    }
}



@media only screen and (max-width: 767px) {
    .mob-lo .col-md-6 {
        padding: 0px;
    }

    .inner_page_main {
        padding: 0 !important;
    }

        .inner_page_main .container {
            max-width: 100%;
            padding: 0;
        }

            .inner_page_main .container .row {
                margin-left: 0 !important;
                margin-right: 0;
            }

    .profile_sec .card .card-header button.btn.btn-link {
        font-size: 19px;
    }

        .profile_sec .card .card-header button.btn.btn-link:after {
            width: 12px;
            height: 12px;
            border-width: 2px;
            right: 25px;
        }

        .profile_sec .card .card-header button.btn.btn-link:after {
            top: 31px;
        }

    .Mypreferences_acc_head h4 {
        font-size: 18px;
    }

    .Mypreferences_acc_head a {
        margin-top: 0;
    }

    .Mypreferences_acc_left h4 {
        font-size: 16px;
    }

    .Mypreferences_acc_left p {
        font-size: 12px;
    }

    .myfavorites_acc_rowsingle h3 {
        font-size: 18px;
    }

    #myinformation_acc .card-body {
        padding-left: 40px;
        padding-right: 40px;
    }

    .Profile_mobile_header {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        margin-top: 15px;
    }

        .Profile_mobile_header .next_arrow {
            float: right;
            margin-left: auto;
        }

    .App-header .Profile_page {
        min-height: 100vh;
        background: #ededed !important;
    }
}

.darktheme {
    background: black !important;
}





@media only screen and (max-width:480px) {
    .profile_sec {
        padding: 0 0 30px 0;
    }

    .inner_page_innersec {
        max-width: 100%;
    }

    .profile_sec .card .card-header button.btn.btn-link {
        padding: 25px 29px;
    }

    .profile_sec .card .accordian_inner .card-body {
        padding: 40px 10px;
    }

    .Mypreferences_acc_left {
        width: 100%;
        margin-bottom: 7px;
    }

    .Mypreferences_acc_row .change_prferences_link {
        margin-top: 39px;
    }

    .myfavorites_acc_rowsingle {
        margin-bottom: 10px;
        box-shadow: 0px 9px 16px rgba(0,0,0,.15);
    }

        .myfavorites_acc_rowsingle p {
            font-size: 14px;
        }

    #myinformation_acc .card-body {
        padding-left: 20px;
        padding-right: 20px;
    }

    #myinformation_acc .btntype3 {
        padding: 15px 25px;
        width: auto;
    }

    .profile_top_sec .profile_img {
        margin-top: -25px;
    }

    .Mypreferences_acc_rowsingle {
        margin-bottom: 10px;
    }
}

#myinformation_acc .common_form_sec form .form-group.namefieds .text-danger:nth-child(2) {
    right: 227px;
}
