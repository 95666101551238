.smaller_font {
    font-size: smaller;
}

.l-margin-20 {
    margin-left: 40px;
}

.instructions-photo img {
    width: unset !important;
}

.calibrate-report_summary .continue_search_landing {
    color: white !important;
}

.OpenTrackAccountModal {
    display: none !important;
}

.calibrate-report_links li a {
    /*color: white !important;*/
}

.calibrate-report_sec {
    padding: 40px 65px 66px;
    background: #e3e3e3;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
}

.calibrate-report_det {
    padding: 40px 65px 66px;
    background: #e3e3e3;
    /*max-width: 690px;*/
    margin-left: auto;
    margin-right: auto;
    opacity: .9;
}

.calibrate_intake {
    white-space:nowrap;
}

.calibrate_intake ul{

    list-style-position: inside;
    padding-left: 0;
}

.calibrate_intake_values {
    background: orange;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
}

.calibrate-report_summary {
    /*padding: 40px 65px 66px;*/
    /*background: white;*/
    /*opacity: 1;*/
    /*max-width: 690px;*/
    /*margin-left: auto;f
    margin-right: auto;*/
    min-width: 380px;
}

.calibrate-results_summary {
    /*padding: 40px 65px 66px;*/
    background: white;
    opacity: 1;
    /*max-width: 690px;*/
    /*margin-left: auto;
    margin-right: auto;*/
    min-width: 380px;
}

.calibrate-report_summary td {
    width: 25%;
    /*padding: 20px 0px 0px 0px !important;*/
    font-weight: bold;
    white-space: nowrap;
    vertical-align: bottom;
}

.calibrate-report_summary thead td {
    white-space: normal;
}

.calibrate-report_summary tbody td {
    width: 25%;
    /*padding: 0px 0px 0px 0px !important;*/
    font-weight: unset;
}

.powered-by {
    font-weight: bold;
    margin-top: 20px;
}

.calibrate-profile {
    font-weight: bold;
    margin-top: 20px;
    text-align: right;
}

.calibrate-disclaimer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.calibrate-report_summary table {
    background-color: unset;
    opacity: unset;
    padding: 10px;
}

.calibrate-amino-acids {
    margin-bottom: 20px;
}

.calibrate-report_summary table, th, td {
    border: unset;
}

.calibrate-report_summary th, td {
    padding: 5px;
}

.calibrate-disclaimer {
    white-space: normal;
    font-size: x-small;
}

.container .row .col-md-12 .inner_page .text-center {
    color: black;
    /*text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;*/
}

.calibrate-report_summary a, .calibrate-report_summary a:visited, .calibrate-text, .calibrate-text tbody td {
    color: black !important;
    font-style: normal;
    font-weight: 500;
}

 .calibrate-report_summary a:active, .calibrate-report_summary a:hover {
     color: #ef2859 !important;
     font-weight:bold !important;
}


 .calibrate_back_btn {
    top: -35px !important;
}

.calibrate-report_summary * {
    font-family: unset !important;
    color: black !important;
}

.calibrate-report_summary div h5 button.btn.btn-link {
    color: white !important;
    padding: 12px !important;
}

.calibrate-report-title {
    color: black !important;
    font-weight: 900 !important;
    font-size: large !important;
}

.calibrate-report-subtitle {
    color: black !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    position: absolute !important;
}

.calibrate-report-subtitle-macros {
    color: black !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}

.borderless, .borderless td, .borderless th {
    border-top: unset !important;
    border-bottom: unset !important;
    border-left: unset !important;
    border-right: unset !important;
}

.pink-border {
    border: 1px solid #ef2859 !important;
}

td .black-border-right {
    border-right: 1px solid black !important;
}

.calibrate-title {
    font-size: xxx-larger !important;
    font-weight: 900 !important;
    line-height: .75;
}

.calibrate-results_summary h1:after {
    display: none !important;
    height: unset !important;
    background: unset !important;
}

.calibrate-subtitle {
    font-weight: 900 !important;
}

.calibrate-poweredby {
    font-size: small !important;
}

.calibrate-macros {
    /*float: left;*/
    width: 74%;
    margin-left: auto;
    margin-right: auto;
}

.calibrate-legend {
    float: right;
    width: 80%;
}

.calibrate-legend td {
    padding: 10px;
}

.calibrate-macros table, .calibrate-legend table {
    min-height: 160px;
}

    .calibrate-legend table {
        min-height: unset;
        max-width: max-content;
        margin-right: auto;
        margin-left: auto;
    }

.calibrate-subreport {
    margin-bottom: 20px;
}

.nowrap {
    white-space: nowrap !important;
}

.blank {
    color: white !important;
}

.calibrate-report_summary img {
    width: 20px;
}

.spacer {
    line-height: .5;
}

tbody td {
    line-height: .75;
    border-color: black;
    border-top: 1px solid black !important;
}

input[type='number'] {
    width: 60px;
    border-style: solid;
    text-align: center;
}

.totals {
    font-weight:bold !important;
}

.footnote {
    font-size: small;
    display: none;
    color: black !important;
    font-weight: bold !important;
}

input {
    border-color: black;
}

.disabled {
    pointer-events: none !important;
    border: none !important;
    border-style: none !important;
    text-align: right !important;
}

.calibrate-macronutrients .fa-info-circle {
    font-family: FontAwesome !important;
    color: #ef2859 !important;
}

.calibrate-macronutrients .fa-info-circle:active, .calibrate-macronutrients .fa-info-circle:hover {
    color: #363636 !important;
}

.fa-info-circle {
    font-family: FontAwesome !important;
    color: #800020 !important;
}

.fa-info-circle:active, .fa-info-circle:hover {
        color: #363636 !important;
    }

.calibrate-report_summary .modal-title {
    color: #ef2859 !important;
}

.calibrate-report_summary .btn-secondary {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

    .calibrate-report_summary .btn-secondary:hover {
        color: #fff !important;
        background-color: #5a6268 !important;
        border-color: #545b62 !important;
    }

    .calibrate-report_summary .btn-secondary:active {
        color: #fff !important;
        background-color: #ef2859 !important;
        border-color: #ef2859 !important;
        box-shadow: none !important;
    }

.pink {
    color: #ef2859 !important;
}

.calories-protein, .calories-carbohydrates, .calories-fat {
    padding-bottom: 10px !important;
}

.customized-macros-created, .customized-macros-synched, .nutrients-synched {
    text-align: center;
    font-weight: 700;
    width: 100%;
}

.macros-buttons {
    margin-left: auto;
    margin-right: auto;
}

.save-macros-button, .update-macros-button, .update-nutrient-targets-button {
    padding: 10px
}

    .update-macros-button div span, .save-macros-button div span {
        font-weight: initial !important;
    }

.modal .calibrate-report-title {
    color: black !important;
    font-weight: 900 !important;
    font-size: larger !important;
}

.nutrientAssessmentThumbnail{
    margin: 0px 5px;
    width: 50px !important;
    border-radius: 3px;
    height: 40px;
    box-shadow: 0px 1px 6px #a5a5a5;
    border: 1px solid #999999;
}

.nutrientAssessmentThumbnail:hover #dashboardDetailsWrapper{
    display: block !important;
    width: 100%;
    height: 100%;
}

.dtls-dashboard-model{
    padding: 0px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    border: none;
}

.dtls-dashboard-model button:focus{
    outline: none;
}

.customize-title {
    font-weight: bold;
    text-align: center;
    font-size: large;
}


@media only screen and (max-width: 500px) {

    body {
        font-size: 12px !important;
    }

    .calibrate_intake {
        white-space: normal;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .calibrate-report_det {
        padding: 10px;
    }

    .col-sm-10 {
        float: unset;
        width: unset;
        margin-bottom: 10px;
    }

    .col-sm-2 {
        float: unset;
        width: unset;
    }

    .calibrate-macros, .calibrate-legend {
        float: unset !important;
        /*width: unset !important;*/
        width: 100%;
        margin-top: 10px;
        min-height: unset;
        
    }


        .calibrate-legend table {
            min-height: unset;
            display: contents;
            margin-bottom: 0px;
        }

    .calibrate-title {
        padding: 10px;
        font-size: 28px;
    }

    .calibrate-subtitle {
        font-size: medium !important;
    }

    .daily-calories-error {
        display: block;
        margin-left: -9px;
    }


    }