.header-navigation {
    /*display: none !important;*/
}

.inner_page_main {
    padding: 70px 0 50px;
}

.inner_page_innersec h1 {
    display: block;
    font-size: 42px;
    width: 100%;
    margin-bottom: 45px;
}

.inner_page_payementsec {
    /*max-width: 475px;*/
    max-width: 100% !important;
    margin: 0 auto;
    margin-bottom: 10%;
}

.inner_page_innersec {
    /*max-width: 475px;*/
    /*max-width: 100% !important;*/
    margin: 0 auto;
    margin-bottom: 10%;
}

.inner_page_innersec_landing {
    max-width: 475px;
    /*max-width: 100% !important;*/
    margin: 0 auto;
    margin-bottom: 10%;
}

.inner_page_innersec_heading {
    max-width: 475px;
    margin: 0 auto;
    /*margin-bottom: 10%;*/
}

.entitys_sec .single_entity {
    max-width: 145px;
    margin: 0 auto;
    display: block;
    padding: 10px 10px;
    border-radius: 16px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.entitys_sec {
    max-width: 555px;
    margin: 0 auto;
}

.nutrients {
    background: #269FDC;
}
.allergence {
    background: #FBB800;
}
.vegitarian {
    background: #ED5119;
}
.keto {
    background: #F0883A;
}
.planets {
    background: #7FBB0A;
}

.cronometer {
    background: white;
    min-height: 170px;
}

.entitys_sec .col-sm-4 {
    margin-bottom: 45px;
}

.entitys_sec .single_entity h4 {
    margin: 0;
    color: #fff;
    min-height: 45px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
}

h4 {
    font-size: 19px;
    font-family: 'Brown Bold';
    font-weight: 600;
}

img {
    max-width: 100%;
}

.continue_search_result {
    text-align: center;
    margin-top: 0px;
}

    .continue_search_result div, .continue_search_result button {
        display: inline-block;
        color: #ef2859;
        /*color: #fff;*/
        font-size: 21px;
        font-family: 'Brown Bold';
        padding: 11px 25px;
        border: 2px solid #ef2859;
        border-radius: 8px;
        /*border-radius: 4px;*/
        background: white;
        /*background: transparent;*/
        cursor: pointer;
        /*border-width: 6px !important;*/
        /*border-style: outset !important;*/
        /*border-color: #ef2859 !important;*/
        /*font-weight: bold;*/
        white-space: nowrap;
    }

    .fill_width div, fill_width button {
        min-width: -webkit-fill-available;
    }

.entity_count {
    position: absolute;
    left: -17px;
    width: 67px;
    height: 67px;
    background: #fff;
    border-radius: 50%;
    top: -17px;
    font-size: 24px;
    line-height: 67px;
}

.nutrients .entity_count {
    color: #269FDC;
    border: 1px solid #269FDC; 
}

.allergence .entity_count {
    color: #FBB800;
}

.vegitarian .entity_count {
    color: #c23f10;
}

.keto .entity_count {
    color: #F0883A;
}

.planets .entity_count {
    color: #7FBB0A;
}

.continue_search_result div:hover, .continue_search_result button:hover {
    color: white;
    background: #ef2859;
}

.entitys_sec .row {
    display: block;
    text-align: center;
}

    .entitys_sec .row .col-sm-4 {
        display: inline-block;
        flex: initial;
        vertical-align: top;
    }

.m-topi-spc {
    margin-top: 15px !important;
    text-align: center;
    /*color: #ef2859;*/
    color: #363636;
    font-size: 20px;
    font-family: 'Brown Bold';
    cursor: pointer;
}

    .m-topi-spc div:hover {
        text-decoration: underline !important;
    }

@media only screen and (min-width: 992px) {
    .continue_search_result div, .continue_search_result button {
        width: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .continue_search_result div, .continue_search_result button {
        width: auto;
    }
}

@media only screen and (min-width:768px) {
    .dashboard_page h1 br {
        display: none;
    }
}

@media only screen and (max-width:767px) {

    .continue_search_result {
        margin-top: 0;
    }

    .inner_page_main.dashboard_page .container {
        max-width: 540px;
        padding: 40px 0;
    }

    .inner_page_main.dashboard_page h1 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width:575px) {
    .entitys_sec .col-sm-4 {
        width: 33.33%;
    }
}


@media only screen and (max-width:480px) {
    .inner_page_innersec h1 {
        font-size: 36px;
    }

        .inner_page_innersec h1::after {
            margin: 8px 0;
        }

    .inner_page_innersec {
        max-width: 300px;
    }

    .entitys_sec .col-sm-4 {
        width: 50%;
        margin-bottom: 30px;
    }

}

@media only screen and (max-width:370px) {
    .inner_page_innersec h1 {
        font-size: 31px;
    }

    .entitys_sec .col-sm-4 {
        padding: 0 10px;
        margin-bottom: 20px;
    }

    .entitys_sec .row {
        margin: 0 -10px;
    }

    .inner_page_innersec {
        max-width: 270px;
    }
}

.subscription_option {
    max-width: 300px;
    cursor: pointer;
}
