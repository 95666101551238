.site_header {
    background: #fff;
    box-shadow: 0 0 33px rgb(0 0 0 / 6%);
    z-index: 2;
    position: relative;
    padding: 50px 0px;
}
.site_header .header_social_icon {
    margin: 0;
    padding: 0;
    list-style: none;
}
.site_header .header_social_icon li {
    display: inline-block;
    margin-right: 37px;
}
.site_header .header_social_icon li span i {
    color: #b2c0d1;
    font-size: 26px;
}
.site_header .header_right_sec {
    float: right;
}
.site_header .header_right_sec span {
    display: inline-block;
    margin-left: 22px;
}
.site_header .header_social_icon li:last-child {
    margin-right: 0;
}
.site_header .header_right_sec span:first-child {
    margin-left: 0;
}
.header_logo_wrap img {
    width: 200px;
}
.tripism_logo img, .amex_logo img {
    width: 100% !important;
}
.tripism_logo img {
    max-width: 200px !important;
}
.partnership_text {
    font-size: larger;
    text-align: center;
    display: none;
}
.footer_logo_wrap img {
   
}
.mobile_header_show .row {
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
}
.site_header .header_social_icon li a:hover i {
    color: #ef2859;
}
.header-profile{
   /*cursor: pointer;*/
}
.header-profile-name {
    color: #ef2859 !important;
    font-weight: 600;
    font-family: 'Brown Bold';
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}
.logout {
    display: inline-block;
    vertical-align: middle;
}
.logout i.fa {
    font-size: 45px;
    color: #828282;
}


/* ============= New Theme UI ============== */

.mainMenu{
    text-align: right;
}

.site_header .mainMenu a{
    text-transform: uppercase;
    font-size: 15px !important;
    font-family: 'Montserrat-Light' !important;
    color: #000 !important;
    margin-right: 30px;
}
.site_header .mainMenu a:last-child{
    margin-right: 0;
}

.site_header .headerLogo img{
    /*max-width: 180px;*/
    margin-right: 30px;
}

.site_header .headerLogo img:last-child{
    margin-right: 0;
}


@media (min-width:769px) and (max-width: 991px) {
    .site_header{
        padding: 15px;
    }
    .headerLogo, .mainMenu{
        text-align: center;
        margin: 5px 0;
    }
}

@media only screen and (max-width: 768px){
    .mobile_logowrap, .hide_desktop {
        display: none;
    }

    .headerLogo{
        text-align: center;
        margin-bottom: 20px;
    }

    .mainMenu{
        text-align: center;
    }
}

@media only screen and (max-width: 767px){
.site_header .row .header_social_icon {
    display: none;
}
.site_header .col-sm-4 {
    margin: 0 auto;
}
.header_logo_wrap a {
    display: inline-block;
}
.header_logo_wrap a img {
    width: 128px !important;
}
.site_header .col-sm-4 .page-name {
    font-size: 20px;
    font-family:'Brown Regular Regular';
    color: #555;
    text-align: center;
}
.headre-menu-item {
    margin-left: 0 !important;
}
.desktop_logowrap, .hide_mobile{
   display: none !important;
}
.site_header .header_social_icon li {
    margin: 0 23px;
}

.mainMenu{
    display: none;
}

.site_header{
    padding: 15px 0px;
}

.headerLogo{
    text-align: center;
    margin-bottom: 0;
}

.site_header .headerLogo img{
    margin: 0px auto;
    max-width: 120px;
    margin-right: 15px;
}

}


@media only screen and (max-width:575px){
    .site_header .col-sm-4:first-child {
    width: auto;
    margin-right: auto;
    float: left;
    margin: 0;
}

.site_header .col-sm-4:last-child {
    width: auto;
    margin-right: 0;
    display: block;
    margin-left: 0;
}

.site_header .col-sm-4:nth-child(2) {
    width: auto;
    margin: 0 auto;
    display: block;
}
}


@media only screen and (max-width:370px){
.header_logo_wrap a img {
    width: 113px !important;
}
}
