.vegetarianvagan_page .entity_page_boxtop {
    background-color: #ED5119;
    background-image: url('/public/static/img/vegetarionbg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
}

.vegetarianvagan_page .entity_page_boxbtminner .chose_sec label span:after {
    background: #ED5119;
}

@media only screen and (max-width: 767px){
.vegetarianvagan_page .col-md-12 {
    padding: 0 !important;
}
.vegetarianvagan_page .inner_page_innersec {
    max-width: 100%;
}
.vegetarianvagan_page .entity_page_boxbtm {
    padding: 0;
    border-radius: 0 !important;
}
.vegetarianvagan_page .entity_page_boxbtminner {
    border-radius: 0;
}
.vegetarianvagan_page .entity_page_boxtop {
    border-radius: 0;
        background-size: 228px;
}
.vegetarianvagan_page h1 {
    display: none !important;
}
.vegetarianvagan_page .entity_page_boxtop h3 {
    max-width: 220px;
}
}

.allergens_agree {
    position: unset !important;
}