.login_form .common_form_secinner {
    /*padding-top: 80px;*/
    /*padding-bottom:40px;*/
}

    .login_form .common_form_secinner form {
        margin-left: 0;
        /*margin-top: 58px;*/
    }

        .login_form .common_form_secinner form .form-group:first-child {
            /* margin-bottom: 0px;
*/
        }

.height-100vh{
    height: 100vh;
}

.forgot_password a {
    font-size: 11px;
    color: #656e82;
    font-family: 'Brown Light';
}

.login_form .password_field {
    margin-bottom: 15px !important;
}

.forgot_password {
    margin-bottom: 23px;
}

.remember_me label input {
    display: none;
}

.remember_me label {
    cursor: pointer;
}

.remember_me span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 33px;
    line-height: 2;
    color: #656e82;
    font-size: 11px;
    font-family: 'Brown Light';
}

    .remember_me span:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #b5b5b5;
        border-radius: 2px;
        background: #f2f2f2;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 0px;
    }

    .remember_me span:after {
        content: '';
        display: none;
        width: 22px;
        height: 22px;
        background: url(/public/static/img/check.png);
        background-repeat: no-repeat;
        position: absolute;
        left: 2px;
        top: -3px;
    }

.remember_me label input[type="checkbox"]:checked + span:after {
    display: block;
}

.common_form_secinner h5 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
}

.tick-i i {
    color: #5abb5f;
    font-size: 21px;
    border: 2px solid #5abb5f;
    padding: 10px;
    border-radius: 100%;
    text-align: center;
}


.common_form_sec.verify_email_form {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,89%);
    -ms-transform: translate(-50%,89%);
    transform: translate(-50%,89%);
}

.microsoftBlueColor{
    color: #269fdc !important;
}

.microsoftBlueColor:hover{
    color: #1f8ec6 !important;
}

.subscriptionMessage {
    max-width: 350px;
    text-align: center;
    font-size: 16px;
    background: #fff;
    padding: 5px;
    line-height: 24px;
    border-radius: 5px;
    margin-top: 15px;
}

@media only screen and (max-width: 767px) {
    .login_form .common_form_secinner {
        padding-top: 0;
        padding-bottom: 0;
    }

        .login_form .common_form_secinner form {
            margin-top: 45px;
        }

}
