.inner_page_main {
    padding: 70px 0 50px;
}
.inner_page_innersec h1 {
    display: block;
    font-size: 42px;
    width: 100%;
    margin-bottom: 45px;
}
.inner_page_innersec {
    max-width: 690px;
    margin: 0 auto;
}
.entitys_sec .single_entity {
    max-width: 145px;
    margin: 0 auto;
    display: block;
    padding: 10px 10px;
    border-radius: 16px;
    text-align: center;
    position: relative;
    cursor: pointer;
}
.entitys_sec {
    max-width: 555px;
    margin: 0 auto;
}
.cronometer {
    background: white;
    min-height: 170px;
}
.entitys_sec .col-sm-4 {
    margin-bottom: 45px;
}
.entitys_sec .single_entity h4 {
    margin: 0;
    color: #fff;
    min-height: 45px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
}
h4 {
    font-size: 19px;
    font-family: 'Brown Bold';
    font-weight: 600;
}
img {
    max-width: 100%;
}
.continue_search_result {
    text-align: center;
    margin-top: 0px;
}
.continue_search_result div {
    display: inline-block;
    font-size: 21px;
    font-family: 'Brown Bold';
    border-radius: 4px;
    background: #fff;
    cursor: pointer; 
}
.entity_count {
    position: absolute;
    left: -17px;
    width: 67px;
    height: 67px;
    background: #fff;
    border-radius: 50%;
    top: -17px;
    font-size: 24px;
    line-height: 67px;
}

.nutrients {
    background: #269FDC;
}
.allergence {
    background: #FBB800;
}
.vegitarian {
    background: #ED5119;
}
.keto {
    background: #F0883A;
}
.planets {
    background: #7FBB0A;
}

.nutrients .entity_count {
    color: #269FDC;
    border: 1px solid #269FDC;
}
.allergence .entity_count {
    color: #FBB800;
    border: 1px solid #FBB800;
}
.vegitarian .entity_count {
    color: #ED5119;
    border: 1px solid #ED5119;
}
.keto .entity_count {
    color: #F0883A;
    border: 1px solid #F0883A;
}
.planets .entity_count {
    color: #7FBB0A;
    border: 1px solid #7FBB0A;
}

.continue_search_result div:hover {
    color: #fff;
    background: #ef2859;
}
.entitys_sec .row {
    display: block;
    text-align: center;
}
.entitys_sec .row .col-sm-4 {
    display: inline-block;
    flex: initial;
    vertical-align: top;
}

.m-topi-spc{
    margin-top: 15px!important;
    text-align: center;
    color: #ef2859;
    font-size: 20px;
    font-family: 'Brown Bold';
    cursor: pointer;
}

.m-topi-spc div:hover{
    text-decoration: underline!important;
}


/*================ New UI Style ================*/

.dashboard_banner div h2{
    font-family:'Montserrat-Light' !important;
    color: #fff;
    font-size: 70px;
    line-height: 80px;
}

.dashboard_banner div p{
    font-weight: 500;
    color: #fff;
    margin: 0;
    font-size: 30px;
    letter-spacing: 1px;
}

.goToBtn{
    font-family: 'Montserrat-Light' !important;
    background-color: #269FDC;
    color: #fff;
    font-size: 33.36px;
    display: inline-block;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 22px 86px;
    border-radius: 35px;
    cursor: pointer;
}

.goToBtn:hover{
    background-color: #333;
    color: #fff;
}

.preferences_wrapper{
    background-color: #fff;
}
.preferences_wrapper h3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat-Light' !important;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px;
    cursor: pointer;
}

.preferences_wrapper h3 .resultBtn{
    display: inline-block;
    background: transparent;
    color: #ef2859;
    padding: 0px;
    border-radius: 40px;
    font-size: 16px;
    text-transform: uppercase;
}

.preferences_box {
    display: flex;
    gap: 2%;
    justify-content: center;
    align-items: flex-start;
    /* max-width: 80vw; */
    flex-wrap: wrap;
}
.preferences_box .single_entity{
    position: relative;
    max-width: 200px;
    width: 100%;
    text-align: center;
    padding: 40px 0 28px;
    margin-bottom: 30px;
    min-width: 200px;
}

.preferences_box .single_entity h4{
    margin: 0;
    color: #fff;
    font-family: 'Montserrat-Light' !important;
    font-weight: 300;
    font-size: 18px;
    background: #000000;
    padding: 3px;
}

.preferences_box .single_entity .imgBox{
    height: 107px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnWrapper{
    text-align: center;
    margin-top: 60px;
}

.btnWrapper .resultBtn{
    display: inline-block;
    background: #7FBB0A;
    color: #fff;
    padding: 15px 40px;
    border-radius: 40px;
    font-size: 18px;
    text-align: center;
    max-width: 294px;
    text-transform: uppercase;
    width: 100%;
    margin: 10px 15px;
    cursor: pointer;
}
.btnWrapper .btnblue{
    background-color: #269FDC;
}


.inspiredWrapper {
    margin-top: 70px;
    padding: 70px 0px 40px 0px;
    background-color: #ebf6fc;
}

.inspiredWrapper .card{
    margin-bottom: 30px;
    border: none;
}

.inspiredWrapper .card h4{
    padding-top: 10px;
    margin-bottom: 5px;
    color: #000;
    line-height: 29px;
    font-weight: 500;
}

.inspiredWrapper .card .card-body{
    position: relative;
}

.inspiredWrapper .travelImg{
    max-height: 220px;
    object-fit: cover;
}

.inspiredWrapper .thumbnailImg{
    position: absolute;
    top: -55px;
    left: 15px;
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0px 0px 10px #929292;
}

.inspiredBox {
    max-width: 69vw;
    margin: auto;
}



/* === Banner-Model === */

.getStartBtn{
    display: inline-block;
    background: #7FBB0A;
    color: #fff;
    padding: 18px 20px;
    border-radius: 30px;
    font-size: 20px;
    text-align: center;
    max-width: 460px;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
    margin-bottom: 30px;
}

#MsGetStartedModal .card .card-header button.btn.btn-link:after, #MsTakesTwoMinutesModal .card .card-header button.btn.btn-link:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    position: absolute;
    transition: all .3s;
    right: 32px;
    top: 24px;
    transform: rotate(45deg);
}

#MsGetStartedModal .card .card-header button.btn.btn-link:not(.collapsed):after, #MsTakesTwoMinutesModal .card .card-header button.btn.btn-link:not(.collapsed):after {
    transform: rotate(225deg);
    top: 20px;
}

#MsGetStartedModal .card-header, #MsTakesTwoMinutesModal .card-header {
    background: #269FDC;
    border: 2px solid #269FDC;
}

#MsGetStartedModal .close, #MsTakesTwoMinutesModal .close{
    position: absolute;
    right: 20px;
}


/* === MS-Get-Start-Model === */

.model-body-getstart .card-header-instructions{
    padding: 15px !important;
}

.model-body-getstart .instructions-photo img {
    width: 100% !important;
    margin-bottom: 10px;
}



@media only screen and (min-width: 992px){

}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .dashboard_banner div h2 {
        font-size: 60px;
        line-height: 70px;
    }

    .goToBtn {
        font-size: 24px;
        padding: 18px 70px;
    }

    .dashboard_banner div p {
        font-size: 24px;
    }

    .preferences_wrapper h3{
        font-size: 26px;
        line-height: 36px;
    }

    /* .preferences_box .single_entity{
        padding: 40px 15px 28px;
    } */

}

@media only screen and (min-width:768px){
.dashboard_page h1 br {
    display: none;
}
}


@media only screen and (max-width:767px){
    .continue_search_result {
        margin-top: 0;
    }
    .inner_page_main.dashboard_page .container {
        max-width: 540px;
        padding: 40px 0;
    }
    .inner_page_main.dashboard_page h1 {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .dashboard_banner{
        min-height: 400px;
        background-size: cover;
        text-align: center;
        background-attachment: unset;
    }
    .dashboard_banner div h2{
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }
    .goToBtn {
        font-size: 18px;
        margin-top: 10px;
        padding: 15px 55px;
    }
    .getStartBtn{
        padding: 18px 15px;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .preferences_wrapper h3{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        line-height: 30px;
        margin-bottom: 30px;
        flex-direction: column;
        align-items: center;
        grid-gap: 15px;
        gap: 15px;
    }

    .dashboard_banner div p{
        font-size: 16px;
        max-width: 70%;
        margin: auto;
        line-height: 23px;
    }
    .preferences_box {
        flex-direction: column;
        padding: 0px 30px;
    }
    .preferences_box .single_entity{
        max-width: 100%;
        width: 100%;
        padding: 40px 0 28px;
    }
    .entity_count {
        width: 67px;
        height: 67px;
        font-size: 30px;
        line-height: 67px;
        left: -10px;
    }   
    
    .inspiredBox{
        max-width: 90vw;
    }

    .inspiredWrapper{
        margin-top: 0;
        padding: 0;
    }
    .mobileHide{
        display: none;
    }
}

@media only screen and (max-width:575px){

}


@media only screen and (max-width:480px){
.inner_page_innersec h1 {
    font-size: 30px !important;
}
.inner_page_innersec h1::after {
    margin: 8px 0;
}
.inner_page_innersec {
    max-width: 300px;
}
.entitys_sec .col-sm-4 {
    width: 50%;
    margin-bottom: 30px;
}
}

@media only screen and (max-width:370px){
.inner_page_innersec h1 {
    font-size: 31px;
}
.entitys_sec .col-sm-4 {
    padding: 0 10px;
    margin-bottom: 20px;
}
.entitys_sec .row {
    margin: 0 -10px;
}
.inner_page_innersec {
    max-width: 270px;
}
}

.fa-video-camera {
    color: #454d55 !important;
    cursor: pointer !important;
}

.fa-video-camera.getting-started {
    color: #ef2859 !important;
    cursor: pointer !important;
    float: unset;
}

.fa-video-camera:active, .fa-video-camera:hover {
    color: #454d55 !important;
}