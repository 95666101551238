.Planethealth_page .entity_page_boxtop {
    background-color: #7FBB0A;
    background-image: url(/public/static/img/planethealtbg.png);
    background-position: bottom right;
    background-repeat: no-repeat;
}
.Planethealth_page .entity_page_boxbtm h4 {
    display: block;
    padding: 0;
    color: #303030;
    font-family: 'Brown Regular Regular';
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: normal;
}
.Planethealth_page .entity_page_boxbtminner .chose_sec label span:after {
    background: #7FBB0A;
}


@media only screen and (max-width: 767px){
.Planethealth_page .col-md-12 {
    padding: 0 !important;
}
.Planethealth_page .inner_page_innersec {
    max-width: 100%;
}
.Planethealth_page .entity_page_boxbtm {
    padding: 0;
    border-radius: 0 !important;
}
.Planethealth_page .entity_page_boxbtminner {
    border-radius: 0;
}
.Planethealth_page .entity_page_boxtop {
    border-radius: 0;
        background-size: 228px;
}
.Planethealth_page h1 {
    display: none !important;
}
.Planethealth_page .entity_page_boxtop {
    background-size: 253px;
}
/*.Planethealth_page .entity_page_boxtop h3 {
    max-width: 185px;
}*/
}