.other_nearby_locations {
    overflow: auto;
    height: 500px;
}

.single_rest_sec {
    margin-bottom: 40px;
}

.single_rest_sectop {
    position: relative;
}

.single_rest_meta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px 50px;
    z-index: 2;
}

    .single_rest_meta h2 {
        color: #fff;
        font-family: 'Brown Bold';
        font-size: 40px;
        line-height: 1;
    }

    .single_rest_meta .rest_location {
        color: #fff;
        font-family: 'Myriad Pro';
        font-size: 15px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

        .single_rest_meta .rest_location .fa {
            font-size: 43px;
            margin-right: 15px;
        }

        .single_rest_meta .rest_location span span {
            display: block;
        }

    .single_rest_meta .favrout_sec {
        position: absolute;
        right: 0;
        top: 2px;
    }

.single_rest_sectop .favrout_sec {
    top: 16px;
    right: 16px;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(0,0,0,.5);
    border-radius: 50%;
    cursor: pointer;
}

    .single_rest_sectop .favrout_sec img {
        width: 20px;
    }

.single_rest_sectop:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.3);
    position: absolute;
    opacity: 0.5
}

.single_rest_sectop:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-image: -webkit-linear-gradient(to bottom, transparent, #111);
    background-image: -moz-linear-gradient(to bottom, transparent, #111);
    background-image: -ms-linear-gradient(to bottom, transparent, #111);
    background-image: linear-gradient(to bottom, transparent, #111);
    opacity: 0.5;
}

.single_rest_secbtm {
    width: 100%;
    background: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

    .single_rest_secbtm > div {
        width: 33.33%;
        text-align: center;
        padding: 26px 10px;
    }

    .single_rest_secbtm .single_rest_secbtminner {
        display: inline-block;
        text-align: left;
    }

        .single_rest_secbtm .single_rest_secbtminner span {
            /*font-size: 12px;*/
            line-height: 1.6;
            font-family: 'Myriad Pro';
            display: inline-block;
            color: #000;
        }

    .single_rest_secbtm .single_rest_secbtm1 span {
        color: #fff;
    }

    .single_rest_secbtm .single_rest_secbtminner img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 24px;
    }

    .single_rest_secbtm .single_rest_secbtminner span {
        display: inline-block;
        vertical-align: top;
    }

    .single_rest_secbtm .single_rest_secbtm3 {
        border-left: 1px solid #eeeeee;
    }

.dish_wrap_inner .dish_single {
    margin-bottom: 30px;
}

    .dish_wrap_inner .dish_single .dish_block {
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 5px 15px rgba(0,0,0,.1);
    }

        .dish_wrap_inner .dish_single .dish_block .dish_blocktop {
            position: relative;
        }

        .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm {
            position: relative;
            padding: 26px 15px 19px;
            background: #fff;
            min-height: 82px;
        }

        .dish_wrap_inner .dish_single .dish_block .dish_blocktop .dish_img {
            height: 150px;
            object-fit: cover;
            width: 100%;
            cursor: pointer;
        }

        .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm .dish_labl {
            top: -17px;
            position: absolute;
            padding: 6px 15px 6px 15px;
            display: inline-block;
            border-radius: 50px;
            font-size: 13px;
            color: #fff;
            font-family: 'Myriad Pro';
            left: 10px;
            text-transform: capitalize;
        }

        .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm h5 {
            margin: 0;
            color: #303030;
            font-size: 18px;
            font-family: 'Brown Bold';
            line-height: 1;
            cursor: pointer;
        }

            .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm h5:hover {
                color: #ef2859;
            }

        .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm .fav_sec {
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            right: 10px;
            top: -20px;
            line-height: 40px;
            text-align: center;
            box-shadow: 0px 3px 10px rgba(0,0,0,0.3);
        }

            .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm .fav_sec img {
                width: 20px;
            }

.single_rest_sectop .single_res_btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0,0,0,.5);
    color: #ccc;
    text-align: center;
    line-height: 53px;
    font-size: 20px;
    cursor: pointer;
}

.single_rest_sectop .more_sec {
    top: 65px;
    right: 16px;
    /*display: none;*/
}

.single_rest_sectop .back_sec {
    left: 16px;
    top: 16px;
    line-height: 36px;
}

    .single_rest_sectop .back_sec img {
        width: 18px;
    }

.res_popup {
    max-width: 650px;
}

    .res_popup .modal-header {
        padding: 0;
        position: relative;
        height: 0;
        border: none;
    }

        .res_popup .modal-header button.close {
            position: absolute;
            top: 11px;
            right: 29px;
            padding: 0;
            z-index: 3;
        }

            .res_popup .modal-header button.close span {
                color: #000;
                font-weight: 400;
                font-size: 35px;
            }

            .res_popup .modal-header button.close:focus {
                outline: none;
            }

    .res_popup .modal-body {
        padding: 20px 45px;
        background: #f8f8f8;
        border-radius: 12px;
    }

    .res_popup .modal-content {
        border: none !important;
        border-radius: 12px;
        background: #f8f8f8;
    }

.res_popup_top {
    position: relative;
}

    .res_popup_top h3 {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 24px;
        font-family: 'Myriad Pro';
        color: #fff;
    }

    .res_popup_top:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-image: -webkit-linear-gradient(transparent, #000);
        opacity: .7;
        background-image: -moz-linear-gradient(transparent, #000);
        background-image: -ms-linear-gradient(transparent, #000);
        background-image: -o-linear-gradient(transparent, #000);
        background-image: linear-gradient(transparent, #000);
    }

.res_popup_mdl {
    display: -werbkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #d0d0d0;
}

    .res_popup_mdl .single_rest_secbtm1 {
        width: 100%;
    }

    .res_popup_mdl .single_rest_secbtm2, .res_popup_mdl .single_rest_secbtm3 {
        width: 50%;
        padding: 19px 40px;
    }

    .res_popup_mdl .single_rest_secbtm1 .single_rest_secbtminner {
        display: -werbkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 11px 10px;
        text-align: center;
    }

        .res_popup_mdl .single_rest_secbtm1 .single_rest_secbtminner span {
            width: 100%;
            color: #fff;
            font-size: 12px;
            font-family: 'Myriad Pro';
        }

            .res_popup_mdl .single_rest_secbtm1 .single_rest_secbtminner span:nth-child(2) {
                width: 60px;
                text-align: center;
            }

    .res_popup_mdl .single_rest_secbtm2 {
        text-align: left;
        border-right: 1px solid #d0d0d0;
    }

        .res_popup_mdl .single_rest_secbtm2 .single_rest_secbtminner {
            display: inline-block;
        }

            .res_popup_mdl .single_rest_secbtm2 .single_rest_secbtminner span {
                display: inline-block;
                text-align: left;
                vertical-align: middle;
            }

    .res_popup_mdl .single_rest_secbtm3 {
        text-align: left;
    }

        .res_popup_mdl .single_rest_secbtm3 .single_rest_secbtminner {
            display: inline-block;
        }

            .res_popup_mdl .single_rest_secbtm3 .single_rest_secbtminner span {
                display: inline-block;
                vertical-align: middle;
            }

.res_pop_sec {
    border: 1px solid #d0d0d0;
}

.res_popup_mdl .single_rest_secbtminner span {
    font-size: 12px;
    color: #000;
    font-family: 'Myriad Pro';
    margin-bottom: 3px;
}

.res_popup_mdl .single_rest_secbtm2 .single_rest_secbtminner span img {
    width: 27px;
    margin-right: 9px;
}

.res_popup_mdl .single_rest_secbtm3 .single_rest_secbtminner span img {
    width: 21px;
    margin-right: 9px;
}

#popaccordion .card {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #d0d0d0;
}

    #popaccordion .card .card-header {
        padding: 0;
        border: none;
        background: #DCDCDC;
    }

        #popaccordion .card .card-header button.btn.btn-link {
            width: 100%;
            display: block;
            padding: 13px 34px;
            text-align: left;
            font-size: 17px;
            font-family: 'Brown Bold';
            color: #000;
            border: none;
            border-radius: 0;
            text-decoration: none !important;
        }

#popaccordion #accordion {
    box-shadow: 0 15px 26px rgba(0, 0, 0,.13);
    border-radius: 15px;
    overflow: hidden;
}

#popaccordion .card .card-header button.btn.btn-link:hover, .profile_sec .card .card-header button.btn.btn-link:focus {
    text-decoration: none;
}

#popaccordion .card .card-header button.btn.btn-link:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    position: absolute;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    right: 32px;
    top: 24px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#popaccordion .card .card-header button.btn.btn-link:not(.collapsed):after {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    top: 20px;
}

#popaccordion .card-body {
    padding: 0;
    background: #eeeeee;
}

#popaccordion .card:last-child {
    border-bottom: none !important;
}

#popaccordion .card:last-child {
    border-bottom: none;
}

#popaccordion .card-body {
    padding: 0 36px 25px;
    font-size: 13px;
    font-family: 'Brown Regular Regular';
    background: #f7f7f7;
    line-height: 1.3;
}

.rest_location a.pink_clr:hover {
    color: #fff !important;
}

.res_popup_top h3 .pop_location {
    font-size: 16px;
    margin-top: 5px;
    padding: 0 5px;
}

    .res_popup_top h3 .pop_location i.fa {
        font-size: 20px;
        margin-right: 7px;
        vertical-align: middle;
    }

    .res_popup_top h3 .pop_location span {
        vertical-align: middle;
    }

.restorent_single_page h3.sectionTitle {
    margin-top: 10px;
    margin-bottom: 17px;
}

.restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
    height: 268px;
}

.no-data-hi h4 {
    font-size: 22px;
}

.top-smv {
    margin-top: 30px;
}

.no-data-hi {
    background-color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 10px;
    width: 100% !important;
}

.edi-centa {
    text-align: center;
    color: #ef2859;
    font-size: 14px;
    font-family: 'Brown Regular Regular';
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

#popplanet .card-body p {
    background: #efeeee !important;
    margin: 0px;
}

#popplanet .card-body ul {
    padding: 5px 17px 3px;
    margin-bottom: 0px;
}

.personalized-desing-static {
    background: #ef2859;
    border-radius: 30px;
    position: absolute;
    bottom: -13px;
    display: block;
    width: 91%;
    padding: 6px;
    color: #fff;
    text-align: center;
    z-index: 3;
    left: 0;
    font-weight: 600;
    right: 0;
    transform: translate(5%,0%);
    font-size: 14px;
}

.locationDesktop {
    display: block;
}

.mobileRestaurantAddress {
    display: none;
}

    .mobileRestaurantAddress h4 {
        color: #000;
        font-size: 16px;
    }

    .mobileRestaurantAddress .card-header {
        background: #DCDCDC !important;
        border: 2px solid #cecece !important;
        padding: 10px 5px;
    }

    .mobileRestaurantAddress .btn-link {
        color: #000 !important;
        font-size: 15px !important;
    }

    .mobileRestaurantAddress .card-body {
        font-size: 13px;
        background: #efeeee !important;
        line-height: 1.3;
    }

        .mobileRestaurantAddress .card-body p {
            font-size: 14px;
        }

.noMenuDescription {
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    color: #000;
}

@media only screen and (min-width: 769px) {

    .single_rest_sectop .back_sec {
        display: none;
    }

    .dish_wrap_inner .dish_single {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media only screen and (min-width: 992px) {
    .mobile_filter_sec {
        display: none;
    }

    .restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
        height: 344px;
    }

    .single_rest_secbtm {
        min-height: 70px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .personalized-desing-static {
        font-size: 12px;
    }

    .locationDesktop {
        display: none;
    }

    .mobileRestaurantAddress {
        display: block;
    }
}

@media only screen and (min-width: 1023px) {
    .dish_wrap_inner .dish_single {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media only screen and (min-width: 1199px) {
    .dish_wrap_inner .dish_single {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media only screen and (max-width: 1200px) {
    .restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
        height: 315px;
    }
}

@media only screen and (max-width: 1280px) {
    .restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
        height: 315px;
    }
}

@media only screen and (max-width: 1199px) {
    .single_rest_meta h2 {
        font-size: 36px;
    }

    .single_rest_meta .rest_location {
        font-size: 14px;
    }

        .single_rest_meta .rest_location .fa {
            font-size: 38px;
            margin-right: 11px;
        }

    .restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
        height: 186px;
    }
}

@media only screen and (max-width: 1024px) {
    .restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
        height: 247px;
    }
}

@media only screen and (min-width: 1025px) {
    .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm {
        min-height: 140px;
    }
}

@media only screen and (max-width: 991px) {
    .restorent_single_page .page_leftsidebar {
        display: none;
    }

    .restorent_single_page .mobile_filter_sec .sidebar_inner {
        width: 100%;
        max-width: 100%;
    }

    .restorent_single_page .mobile_filter_sec {
        margin-bottom: 10px;
        margin-top: 40px;
    }

    .restorent_single_page .rating_sec .rating_sec_inner > div {
        width: 100%;
        text-align: center;
    }

    .restorent_single_page .rating_sec .rating_sec_inner img {
        display: block;
    }

    .restorent_single_page .sidebar_inner .sidebar_sinle_level .calory_filter {
        height: auto !important;
    }

    .locationDesktop {
        display: none;
    }

    .mobileRestaurantAddress {
        display: block;
    }
}


@media only screen and (max-width: 767px) {
    .personalized-desing-static {
        width: 100%;
        transform: translate(0%,0%);
        bottom: 0px;
    }

    .restorent_single_page .page_rightsidebar {
        margin-top: 8px;
    }

    .restorent_single_page .single_rest_meta {
        padding: 15px 27px;
    }

    .single_rest_meta h2 {
        font-size: 24px;
        font-family: 'Myriad Pro';
        color: #fff;
        margin-bottom: 14px;
    }

    .single_rest_secbtm .single_rest_secbtminner img {
        margin-right: 10px;
    }

    .rating_sec h5 {
        display: none;
    }

    .single_rest_sec {
        margin-bottom: 0;
    }

    .rating_sec {
        background: #fff;
        border-top: 1px solid #f7f7f7;
        padding: 0;
    }

    .restorent_single_page .rating_sec .rating_sec_inner > div {
        margin-right: 0;
        padding: 18px 20px;
    }

    .restorent_single_page .rating_sec .rating_sec_inner .rating_wrap {
        border-right: 1px solid #f7f7f7;
    }

    .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm h5 {
        font-size: 23px;
        font-family: 'Myriad Pro';
        line-height: 1.3;
    }

    .dish_wrap_inner .dish_single .dish_block {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row-reverse;
        -moz-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        background: #fff;
        align-items: center;
    }

    .dish_wrap_inner .dish_single {
        width: 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

        .dish_wrap_inner .dish_single .dish_block .dish_blocktop {
            width: 50%;
        }

        .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm {
            width: 50%;
            min-height: auto;
            position: static;
            padding: 25px;
        }

            .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm .dish_labl {
                display: none;
            }

    .restorent_single_page .rating_sec .rating_sec_inner img {
        display: inline-block;
        vertical-align: middle;
    }

    .rating_sec .rating_sec_inner a {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        float: none;
    }

    .restorent_single_page .rating_sec .rating_sec_inner .rating_wrap a {
        margin-left: 13px;
    }

    .restorent_single_page .rating_sec .table_wrap a {
        margin-right: 10px;
    }

    .single_rest_secbtm .single_rest_secbtminner img {
        margin-bottom: 5px;
    }

    .single_rest_secbtm .single_rest_secbtm2 img {
        width: 25px;
    }

    .single_rest_secbtm .single_rest_secbtm3 img {
        width: 19px;
    }

    .single_rest_secbtm > div {
        padding: 13px 10px 10px;
    }

    .single_rest_secbtm .single_rest_secbtm1 {
        padding-top: 14px;
    }

    .restorent_single_page .mobile_filter_sec h3 {
        font-size: 18px;
        font-family: 'Myriad Pro';
        font-weight: 700;
        padding-left: 14px;
        margin-bottom: 19px;
    }

    .restorent_single_page .mobile_filter_sec h3 {
        padding-left: 0;
    }

    .res_popup .modal-header button.close {
        right: 26px;
        top: 19px;
        z-index: 2;
    }

        .res_popup .modal-header button.close span {
            color: #000 !important;
        }

    .restorent_list_page .sidebar_inner h4 {
        padding-left: 15px;
    }

    .restorent_list_page .sidebar_inner ul.calory_filter {
        padding-left: 15px;
    }

    .locationDesktop {
        display: none;
    }

    .mobileRestaurantAddress {
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .restorent_single_page .rating_sec .rating_sec_inner > div {
        padding: 13px 8px;
    }

    .restorent_single_page .rating_sec .rating_wrap > img {
        width: 88px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
    }

    .restorent_single_page .rating_sec .rating_sec_inner .rating_wrap a {
        display: inline-block;
        vertical-align: middle;
    }

    .restorent_single_page .rating_sec .rating_sec_inner .table_wrap > img {
        width: 79px;
    }

    .restorent_single_page .rating_sec .table_wrap a {
        font-size: 11px;
    }

    .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm h5 {
        font-size: 18px;
    }

    .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm {
        padding: 0 5px 0 20px;
    }

        .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm .fav_sec {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }

            .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm .fav_sec img {
                width: 20px;
            }

    .dish_wrap_inner .dish_single .dish_block .dish_blocktop img.dish_img {
        min-height: 120px;
        object-fit: cover;
    }

    .restorent_single_page .page_rightsidebar {
        padding: 0;
        margin-top: 0;
    }

    .single_rest_sectop > img {
        min-height: 274px;
        object-fit: cover;
    }

    .restorent_single_page .single_rest_meta {
    }

    .restorent_single_page .mobile_filter_sec h3 {
        padding-left: 13px;
    }

    .sidebar_inner ul.calory_filter li {
        margin-right: 0;
        margin-left: 10px;
    }

    .restorent_single_page .mobile_filter_sec h4 {
        padding-left: 15px;
    }
    /*.row.dish_wrap_inner {
    margin: 0;
}*/
    .res_popup .modal-body {
        padding: 25px;
    }

    .res_popup .modal-header button.close {
        top: 10px;
        right: 22px;
    }

    .res_popup_mdl .single_rest_secbtm1 .single_rest_secbtminner {
        display: block;
        padding: 8px 0;
    }

        .res_popup_mdl .single_rest_secbtm1 .single_rest_secbtminner span {
            width: auto !important;
            margin: 0 5px;
            line-height: 1.2 !important;
            display: block;
        }

    .res_popup_mdl .single_rest_secbtm2, .res_popup_mdl .single_rest_secbtm3 {
        padding: 15px 7px 10px 7px;
        text-align: left;
    }

    #popaccordion .card-body {
        padding: 0 15px 15px 15px;
    }

    #popaccordion .card .card-header button.btn.btn-link {
        padding: 16px 15px;
    }

    .res_popup_top img {
        min-height: 250px;
        object-fit: cover;
    }

    .res_popup_top h3 {
        font-size: 21px;
    }

    .restorent_list_page .sidebar_inner ul.calory_filter {
        padding-left: 0;
    }

    .restorent_single_page h3.sectionTitle {
        font-size: 24px;
    }

    .other_nearby_locations {
        height: auto;
    }
}

@media only screen and (max-width: 370px) {
    .restorent_single_page .rating_sec .rating_wrap > img {
        width: 75px;
    }

    .restorent_single_page .rating_sec .rating_sec_inner .table_wrap > img {
        width: 63px;
    }

    .restorent_single_page .rating_sec .table_wrap a {
        font-size: 10px;
    }

    .restorent_single_page .single_rest_meta {
        padding: 24px 18px;
    }

    .single_rest_secbtm > div {
        padding: 13px 5px 10px;
    }

    .single_rest_secbtm .single_rest_secbtm2 img {
        width: 20px;
    }

    .single_rest_secbtm .single_rest_secbtminner img {
        margin-right: 5px;
    }

    .single_rest_secbtm .single_rest_secbtminner span {
        font-size: 11px;
    }

    .single_rest_secbtm .single_rest_secbtm3 img {
        width: 17px;
    }
}

.noFiltter {
    font-size: 19px !important;
    color: #000 !important;
    text-align: center !important;
}

.restaurentImage {
    position: relative;
    width: 100%;
}

.filter-text {
    color: #5a5f69;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
}

.more_sec.single_res_btn {
    /*display: none;*/
}

.no-data-hi .noMenuDescription a {
    color: #269fdc !important;
}

.eatInfoModel p strong {
    font-size: 16px;
    color: #000;
}

.eatInfoModel li {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 23px;
}

.eatInfoModel p {
    font-size: 15px;
}
