
.restaurants_wrap h3 {
    margin-bottom: 0;
}

.restaurants_wrap .restaurant_address {
    color: #656e82;
    font-size: 18px;
    font-family: 'Brown Light';
}

    .restaurants_wrap .restaurant_address i.fa {
        font-size: 20px;
        margin-right: 7px;
    }

    .restaurants_wrap .restaurant_address span {
        font-family: 'Brown Regular Regular';
    }

.restaurants_wrap_inner .restaurants_single {
    margin-top: 30px;
}

    .restaurants_wrap_inner .restaurants_single .restaurants_block {
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 5px 15px rgba(0,0,0,.1);
    }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blocktop {
            position: relative;
        }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blocktop img.restaurant_img {
                display: block;
                width: 100%;
                height: 140px;
                object-fit: cover;
            }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blocktop .res_labl {
                position: absolute;
                background: #517806;
                padding: 6px 15px 6px 15px;
                display: inline-block;
                border-radius: 50px;
                font-size: 13px;
                color: #fff;
                font-family: 'Myriad Pro';
                bottom: 13px;
                left: 10px;
            }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blocktop .fav_sec {
                position: absolute;
                right: 16px;
                top: 16px;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: rgba(0,0,0,.3);
                border-radius: 50%;
                text-align: center;
            }

                .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blocktop .fav_sec img {
                    width: 22px;
                }

                .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blocktop .fav_sec.active {
                    background: #ef2859;
                }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm {
            position: relative;
            padding: 36px 10px 22px;
            background: #fff;
        }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm h5 {
                color: #303030;
                font-size: 18px;
                font-family: 'Brown Bold';
                margin: 0;
                cursor: pointer;
            }

                .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm h5:hover {
                    color: #ef2859;
                }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm p {
                margin: 0;
                line-height: 1.2;
                font-size: 15px;
                color: #656e82;
                font-family: 'Brown Light';
            }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm {
        }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm .res_Menuitems {
                position: absolute;
                right: 12px;
                top: -25px;
                text-align: center;
            }

                .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm .res_Menuitems span {
                    display: block;
                    color: #656e82;
                    font-size: 12px;
                    font-family: 'Brown Bold';
                }

                    .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm .res_Menuitems span.itemcount {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        font-family: 'Myriad Pro';
                        font-size: 17px;
                        margin: 0 auto;
                        box-shadow: 1px 3px 9px rgba(0,0,0,.3);
                    }

.restaurants_wrap .See_more {
    margin-top: 38px;
    text-align: left;
}

.restaurants_filter_sec {
    display: inline-block;
    width: 100%;
    background: #fff;
    padding: 14px 5px;
    border-radius: 10px;
    margin-top: 7px;
    margin-bottom: 15px;
}

    .restaurants_filter_sec label {
        font-family: 'Brown Bold';
        font-size: 13px;
        color: #4e4e4e;
        display: block;
        margin-bottom: 4px;
        text-transform: uppercase;
    }

    .restaurants_filter_sec .form-group {
        width: 100%;
        float: left;
        padding: 0 12px;
    }

    .restaurants_filter_sec .form-control {
        background: #ebebeb;
        border-radius: 7px;
        border-color: #b1b1b1;
    }

    .restaurants_filter_sec .zip_field {
        clear: left;
    }

.or_div {
    padding: 0 11px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: 'Brown Bold';
    color: #333;
}

.single_rest_sectop div {
    z-index: 1;
}

.price_btns {
    max-width: 287px;
    margin-top: 18px;
}

.autocomplete-dropdown-container > * {
    padding: 3px 10px;
}

.restorent_list_page .distance_btns_right {
    float: right;
    width: 40%;
    margin-top: 11px;
}

.restorent_list_page .price_btns {
    margin-top: 40px;
}

.See_more {
    margin-top: 15px;
}

.res_Menuitems {
    /*display: none;*/
}

.restorent_list_page .res_grey {
    background: rgba(255,255,255,.7);
}


@media only screen and (min-width: 1025px) {
    .restaurants_wrap_inner .restaurants_single {
        margin-top: 30px;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm {
            min-height: 160px;
        }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm h5 {
        font-size: 14px !important;
    }

    .dish_wrap_inner .dish_single .dish_block .restaurants_blockbtm {
        min-height: 100px !important;
    }
}

@media only screen and (max-width: 767px) {
    .restaurants_wrap .restaurant_address {
        position: relative;
        padding-left: 26px;
        margin-top: 7px;
    }

        .restaurants_wrap .restaurant_address i.fa {
            position: absolute;
            left: 2px;
            top: 2px;
            font-size: 24px;
        }

    .restaurants_filter_sec {
        padding: 0 !important;
        background: none !important;
        margin: 17px 0 0 0 !important;
    }

    .restaurants_wrap_inner .restaurants_single {
        width: 50%;
    }

    .restaurants_wrap .See_more {
        margin: 25px 0 25px 0 !important;
        text-align: center !important;
    }

    .restorent_list_page .sidebar_inner .distance_btns {
        padding: 0 15px;
        width: 100%;
        max-width: 100%;
    }

    .restorent_list_page .distance_btns {
        max-width: 100%;
    }

    .restorent_list_page .sidebar_inner .sidebar_sinle_level {
        margin-bottom: 16px;
    }

        .restorent_list_page .sidebar_inner .sidebar_sinle_level:last-child {
            margin-bottom: 0;
        }

    .restorent_list_page .distance_btns_right {
        width: 100%;
        margin-bottom: 22px;
    }
}


@media only screen and (max-width: 480px) {
    .restaurants_filter_sec .form-group {
        width: 100%;
        padding: 0;
    }

    .restaurants_wrap_inner .restaurants_single {
        width: 100%;
        margin-top: 20px;
    }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm h5 {
            font-size: 16px;
        }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm p {
            font-size: 14px;
        }

        .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm {
            padding-bottom: 24px;
        }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm .res_Menuitems span.itemcount {
                font-size: 18px;
                width: 40px;
                height: 40px;
            }

            .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm .res_Menuitems {
                top: -20px;
            }

                .restaurants_wrap_inner .restaurants_single .restaurants_block .restaurants_blockbtm .res_Menuitems span {
                    font-size: 10px;
                }

    .or_div {
        padding: 0;
    }
}
