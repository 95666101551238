.page_without_sidebar {
    padding: 40px 0;
    min-height: 100vh;
    background: #f5f5f5;
}

.order_top_row {
    background: #Fff;
    margin: 0;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 5px 28px rgba(0,0,0,.1);
}

.order_top_row {
    margin-bottom: 40px;
}

.date_time_wrap {
    position: relative;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 27px;
}

    .date_time_wrap span.datewrap {
        font-family: 'Brown Bold';
        margin-right: 30px;
    }

    .date_time_wrap:after {
        content: '';
        display: block;
        width: 40px;
        height: 5px;
        background: #ef2859;
        margin-top: 11px;
    }

.order_top_left h2 {
    font-family: 'Brown Bold';
    font-size: 42px;
    line-height: 1;
    width: 100%;
    margin: 0 0 20px 0;
    color: #303030;
}

.order-lbl_wrap {
    margin-bottom: 25px;
}

    .order-lbl_wrap > div {
        padding: 6px 24px 6px 30px;
        display: inline-block;
        border-radius: 50px;
        font-size: 14px;
        color: #fff;
        font-family: 'Myriad Pro';
    }

        .order-lbl_wrap > div img {
            margin-left: 13px;
        }

.edit_order a {
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Myriad Pro';
    color: #fff !important;
}

.order_btm #dishaccordion {
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0px 6px 19px rgba(0,0,0,.1);
}

.qty_box {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    border-radius: 30px;
    box-shadow: 0px 5px 15px rgba(0,0,0,.1);
    margin-bottom: 15px;
}

span.qty_btn {
    width: 32px;
    display: inline-block;
    line-height: 34px;
    text-align: center;
    font-size: 35px;
    float: left;
    height: 40px;
    cursor: pointer;
    color: #999;
}

.qty_box input {
    width: 30px;
    height: 40px;
    padding: 4px;
    float: left;
    border-radius: 0;
    border: none;
    text-align: center;
    font-family: 'Myriad Pro';
}

    .qty_box input::-webkit-outer-spin-button,
    .qty_box input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .qty_box input[type=number] {
        -moz-appearance: textfield;
    }

.edit_order {
    display: inline-block;
    padding: 9px 10px;
    color: #fff !important;
    line-height: 1;
    font-size: 11px;
    text-transform: uppercase;
    border: none;
    font-family: 'Brown Bold';
    border-radius: 10px;
    cursor: pointer;
    background: #ef2859;
    margin-left: 20px;
    position: relative;
    top: -4px;
}

    .edit_order:hover {
        opacity: .9;
    }

    .edit_order a:hover, .edit_order a:focus {
        background: #ef2859 !important;
        color: #fff !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }

.order_top_btm ul {
    margin: 0;
    padding: 0;
    display: inline-block;
    list-style: none;
}

    .order_top_btm ul li {
        line-height: 1;
        display: inline-block;
        margin-right: 18px;
        font-size: 13px;
    }

.order_top_btm .Edit_ordercat {
    font-size: 13px;
    text-transform: uppercase;
}

.order_top_btm {
    margin-top: 25px;
}

.date_time_wrap {
    display: none;
}

.order-lbl_wrap {
    display: none;
}

.cent-head-meal {
    text-align: center;
    width: 100%;
}

    .cent-head-meal h2 {
        font-family: 'Brown Bold';
        font-size: 30px;
    }

    .cent-head-meal h3 {
        font-size: 25px;
        margin-bottom: 10px;
    }

.mymeal-tab .nav-tabs {
    border-bottom: 1px solid #ffffff;
}

    .mymeal-tab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: #ffffff;
        background-color: #ef2859;
        border-color: #ef2859 #dee2e6 #fff;
    }

    .mymeal-tab .nav-tabs .nav-link {
        border: white;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        border-radius: 10px;
        color: #ffffff;
        background-color: #ef2859;
        border-color: #ef2859 #dee2e6 #fff;
    }

    .mymeal-tab .nav-tabs .nav-item {
        margin: 0px 10px 0px 0px;
    }

.mymeal-tab .tab-content {
    margin-top: 9px;
}

.size-bbox span {
    border: 1px solid #b7b7b7;
    padding: 5px;
    border-radius: 10px;
    margin-right: 3px;
}

.size-bbox {
    margin-top: 15px;
    margin-bottom: 20px;
}

.top-scci {
    padding: 10px 0 0;
}

    .top-scci .allergens_single label .allergens_singleinner_img {
        padding: 10px 0 0;
    }

    .top-scci .allergens_single {
        margin-bottom: 10px;
    }

    .top-scci h4 {
        font-size: 19px !important;
    }

.menuListCart .pill {
    padding: 0px;
}

.menuListCart .mbPill {
    margin-top: 10px;
    margin-bottom: 10px;
}

    .menuListCart .mbPill span {
        background: #ef2859 !important;
        padding: 5px 16px 8px 15px !important;
        margin-right: 9px !important;
        /* line-height: 1px; */
        border: none;
        letter-spacing: 1px;
        color: #fff;
        border-radius: 50px;
        margin-bottom: 3px !important;
        display: inline-block;
    }

.menuListCart .card-body .pill {
    background: #ef2859 !important;
    padding: 6px 16px 8px 15px !important;
    margin: 0px 9px 4px 0px;
    line-height: 1;
    border: none;
    letter-spacing: 1px;
    color: #fff;
    border-radius: 50px;
}

.menuListCart .card-body {
    margin-top: 12px;
    display: inline-block;
    width: 100%;
    padding: 1rem;
}

.nutrients_MM table {
    margin-bottom: 0px;
}

    .nutrients_MM table th,
    .nutrients_MM table td {
        font-size: 13px;
    }

.menuListCart .nutrients_MM {
    width: 100%;
    float: initial;
    padding: 0;
    margin: 0;
    background: #fff;
    margin-bottom: 0px !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ef2859;
    border-color: #ef2859;
    box-shadow: none !important;
}

#menuItemSection > .col-xs-12 {
    /*  margin: 0px auto;*/
}

.menuListCart .card-body span {
    display: inline-block;
}

.menuListCart {
    padding: 0px 0px 25px !important;
}

.nutrients_MM {
    margin-bottom: 30px !important;
}

.ri-siide {
    text-align: right;
}

.centss {
    margin: 0px auto;
}

.R-m-mell {
    color: #fff;
    background-color: #ef2859;
    border-color: #ef2859;
    position: absolute;
    right: 0px;
    top: -15px;
}

    .R-m-mell:hover {
        opacity: .9;
        color: #fff;
        background-color: #ef2859;
        border-color: #ef2859;
    }

    .R-m-mell:focus {
        box-shadow: none;
        background-color: #ef2859;
        border-color: #ef2859;
    }

#order_top_api {
    position: relative;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ef2859;
    border-color: #ef2859;
    box-shadow: none !important;
}

@media only screen and (min-width: 768px) {
    .order_top_btm {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .R-m-mell {
        position: static;
        margin: 0px auto;
        display: block;
        margin-bottom: 10px;
    }

    .order_top_left.col-md-7 {
        max-width: 100%;
        flex: 100%;
    }

    .mob-v {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media only screen and (max-width: 1199px) {
    .date_time_wrap {
        font-size: 20px;
    }

    .order_top_left h2 {
        font-size: 26px;
        margin-bottom: 23px;
    }

    .order-lbl_wrap {
        margin-bottom: 29px;
    }
}

@media only screen and (max-width: 991px) {
    .date_time_wrap {
        margin-bottom: 18px;
        font-size: 18px;
    }

    .order_top_left h2 {
        margin-bottom: 15px;
    }

    .order-lbl_wrap {
        margin-bottom: 14px;
    }

    .date_time_wrap:after {
        margin: 6px 0 0;
    }

    .order_btm .col-md-7 {
        width: 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .menuListCart .mbPill span {
        display: inline-block;
        padding: 1px 16px 1px 15px !important;
        margin: 0px 9px 7px 0px !important;
    }

    .R-m-mell {
        position: static;
        margin: 0px auto;
        display: block;
        margin-bottom: 10px;
    }

    .ri-siide {
        text-align: center;
        margin-bottom: 15px;
    }

    .nutrients_MM {
        margin-bottom: 20px !important;
    }

    .myMealRemoveBtn {
        position: static !important;
    }

    .menuListCart {
        padding: 15px !important;
    }

    .mymeal-tab .nav-tabs .nav-item {
        margin: 0px 10px 9px 0px;
    }

    .order_top_left {
        /* width: 50%;*/
        padding: 0;
    }

    .mob-v {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .order_top_right {
        width: 50%;
        padding: 0;
    }

    .order_top_row {
        padding: 30px 22px;
        align-items: flex-end;
        -webkit-box-align: flex-end;
        -moz-box-align: flex-end;
        -ms-flex-align: flex-end;
    }

    .order_top_right img.singledish_img {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .order-lbl_wrap {
        display: none;
    }

    .edit_order {
        display: none;
    }

    .order_top_btm {
        display: block;
    }

    .qty_box {
        display: -nlineflex;
        display: -moz-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-block;
    }

    .order_top_left h2 {
        line-height: 1.3;
        margin-bottom: 20px;
    }

    .date_time_wrap {
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 480px) {
    .date_time_wrap span.datewrap {
        margin-right: 8px;
    }

    .date_time_wrap {
        font-size: 13px;
    }

    .order_top_left h2 {
        font-size: 18px;
        padding-right: 12px;
    }

    .order_top_right img.singledish_img {
        min-height: 132px;
        object-fit: cover;
    }

    .order_top_row {
        padding: 28px 10px;
    }

    .date_time_wrap:after {
        width: 30px;
    }

    .order_top_btm ul li {
        margin-right: 9px;
    }

    .order_top_row {
        margin-bottom: 28px;
    }

    .page_without_sidebar {
        padding: 28px 0;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background: #fff;
    border: 1px solid #000;
}

.mealNotFound {
    color: #211d1d
}

.hide {
    display: none;
}
