.forgot_password_form form {
    margin-top: 80px;
}

.forgot_password {
    color: unset !important;
}
.common_form_secinner .forgot_password {
    color: unset !important;
}

.forgot_password_form form .submit_btn {
    margin-top: 65px;
}

.forgot_password_form .common_form_secinner {
    padding-bottom: 95px;
}

.f_back_btn button {
    /*  float: left;*/
    border: none;
    background: transparent;
}

.f_back_btn i {
    font-size: 16px;
    margin: 0px 0px 5px;
    color: #a7a7a7;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .f_back_btn i {
        color: #fff;
    }

    .forgot_password_form form {
        margin-top: 35px;
    }

    .forgot_password_form .common_form_secinner {
        padding-bottom: 0;
    }

    .forgot_password_form form .submit_btn {
        margin-top: 50px;
    }
}
