.menuContainer{
    padding: 10px 20px !important;
}
.site_header {
    background: #fff;
    box-shadow: 0 0 33px rgb(0 0 0 / 6%);
    z-index: 2;
    position: relative;
}
.menuContainer .container {
    max-width: 100%;
}
.site_header .header_social_icon {
    margin: 0;
    padding: 0;
    list-style: none;
}
.site_header .header_social_icon li {
    display: inline-block;
    margin-right: 37px;
}
.site_header .header_social_icon li span i {
    color: #b2c0d1;
    font-size: 26px;
}
.site_header .header_right_sec {
    float: right;
}
.site_header .header_right_sec span {
    display: inline-block;
    margin-left: 22px;
}
.site_header .header_social_icon li:last-child {
    margin-right: 0;
}
.site_header .header_right_sec span:first-child {
    margin-left: 0;
}
.header_logo_wrap img {
    width: 200px;
}
.mobile_header_show .row {
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
}
.site_header .header_social_icon li a:hover i {
    color: #ef2859;
}
.header-profile{
   /*cursor: pointer;*/
}
.header-profile-name {
    color: #ef2859 !important;
    font-weight: 600;
    font-family: 'Brown Bold';
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}
.logout {
    display: inline-block;
    vertical-align: middle;
}
.logout i.fa {
    font-size: 45px;
    color: #828282;
}


@media only screen and (min-width: 768px){
.mobile_logowrap, .hide_desktop {
    display: none;
}
}



@media only screen and (max-width: 767px){
.site_header .row .header_social_icon {
    display: none;
}
.site_header .container {
    padding: 5px 10px;
}
.site_header .col-sm-4 {
    margin: 0 auto;
}
.header_logo_wrap a {
    display: inline-block;
}
.header_logo_wrap a img {
    width: 128px !important;
}
.site_header .col-sm-4 .page-name {
    font-size: 20px;
    font-family:'Brown Regular Regular';
    color: #555;
    text-align: center;
}
.headre-menu-item {
    margin-left: 0 !important;
}
.desktop_logowrap, .hide_mobile{
   display: none !important;
}
.site_header .header_social_icon li {
    margin: 0 23px;
}
}


@media only screen and (max-width:575px){
    .site_header .col-sm-4:first-child {
    width: auto;
    margin-right: auto;
    float: left;
    margin: 0;
}

.site_header .col-sm-4:last-child {
    width: auto;
    margin-right: 0;
    display: block;
    margin-left: 0;
}

.site_header .col-sm-4:nth-child(2) {
    width: auto;
    margin: 0 auto;
    display: block;
}
}


@media only screen and (max-width:370px){
.header_logo_wrap a img {
    width: 113px !important;
}
}
