.inner_page_main {
    padding: 70px 0 50px;
}

.inner_page_innersec h1 {
    display: block;
    font-size: 42px;
    width: 100%;
    margin-bottom: 45px;
}

.inner_page_innersec {
    max-width: 690px;
    margin: 0 auto;
}

.entitys_sec .single_entity {
    max-width: 145px;
    margin: 0 auto;
    display: block;
    padding: 10px 10px;
    border-radius: 16px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.normal-dashboard-page .nutrients {
    background: #ef2859;
}

.normal-dashboard-page .entitys_sec {
    max-width: 555px;
    margin: 0 auto;
}

.normal-dashboard-page .allergence {
    background: #00a994;
}

.normal-dashboard-page .vegitarian {
    background: #f2882d;
}

.normal-dashboard-page .cronometer {
    background: white;
    min-height: 170px;
}

.normal-dashboard-page .keto {
    background: #0071b5;
}

.normal-dashboard-page .planets {
    background: #00a651;
}

.normal-dashboard-page .entitys_sec .col-sm-4 {
    margin-bottom: 45px;
}

.normal-dashboard-page .entitys_sec .single_entity h2 {
    margin: 0;
    color: #fff;
    min-height: 45px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    /* background: #171819; */
}

    .normal-dashboard-page .entitys_sec .single_entity h2:after {
        display: none;
    }

.normal-dashboard-page h2 {
    font-size: 19px !important;
    font-family: 'Brown Bold';
    font-weight: 600;
}

img {
    max-width: 100%;
}

.continue_search_result {
    text-align: center;
    margin-top: 0px;
}

    .continue_search_result div {
        display: inline-block;
        /*color: #ef2859;*/
        font-size: 21px;
        font-family: 'Brown Bold';
        /*padding: 11px 25px;
    border: 2px solid #ef2859;*/
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
    }

.normal-dashboard-page .entity_count {
    position: absolute;
    right: -17px;
    left: unset;
    border: none !important;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: -17px;
    box-shadow: 0px 1px 11px rgba(0,0,0,.6);
    font-size: 24px;
    font-family: 'Brown Bold';
    font-weight: 600;
    line-height: 40px;
}

.normal-dashboard-page .nutrients .entity_count {
    color: #ef2859;
}

.normal-dashboard-page .allergence .entity_count {
    color: #00a994;
}

.normal-dashboard-page .vegitarian .entity_count {
    color: #f2882d;
}

.normal-dashboard-page .keto .entity_count {
    color: #0071b5;
}

.normal-dashboard-page .planets .entity_count {
    color: #0ea758;
}

.normal-dashboard-page .continue_search_result div:hover {
    color: #fff;
    background: #ef2859;
}

.entitys_sec .row {
    display: block;
    text-align: center;
}

    .entitys_sec .row .col-sm-4 {
        display: inline-block;
        flex: initial;
        vertical-align: top;
    }

.m-topi-spc {
    margin-top: 15px !important;
    text-align: center;
    color: #ef2859;
    font-size: 20px;
    font-family: 'Brown Bold';
    cursor: pointer;
}

    .m-topi-spc div:hover {
        text-decoration: underline !important;
    }

@media only screen and (min-width: 992px) {
    .continue_search_result div {
        width: 40%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .continue_search_result div {
        width: 40%;
    }
}

@media only screen and (min-width:768px) {
    .dashboard_page h1 br {
        display: none;
    }
}

@media only screen and (max-width:767px) {
    .continue_search_result div {
        /*width: 81%;*/
    }

    .continue_search_result {
        margin-top: 0;
    }

    .inner_page_main.dashboard_page .container {
        max-width: 540px;
        padding: 40px 0;
    }

    .inner_page_main.dashboard_page h1 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width:575px) {
    .entitys_sec .col-sm-4 {
        width: 33.33%;
    }
}


@media only screen and (max-width:480px) {
    .inner_page_innersec h1 {
        font-size: 30px !important;
    }

        .inner_page_innersec h1::after {
            margin: 8px 0;
        }

    .inner_page_innersec {
        max-width: 300px;
    }

    .entitys_sec .col-sm-4 {
        width: 50%;
        margin-bottom: 30px;
    }

    .entity_count {
        width: 33px;
        height: 33px;
        font-size: 19px;
        line-height: 33px;
    }
}

@media only screen and (max-width:370px) {
    .inner_page_innersec h1 {
        font-size: 31px;
    }

    .entitys_sec .col-sm-4 {
        padding: 0 10px;
        margin-bottom: 20px;
    }

    .entitys_sec .row {
        margin: 0 -10px;
    }

    .inner_page_innersec {
        max-width: 270px;
    }
}

.fa-video-camera {
    /*color: #ef2859 !important;*/
    color: #454d55 !important;
    cursor: pointer !important;
    /*float: right;*/
}

    .fa-video-camera.getting-started {
        color: #ef2859 !important;
        /*color: #454d55 !important;*/
        cursor: pointer !important;
        float: unset;
    }

    .fa-video-camera:active, .fa-video-camera:hover {
        color: #454d55 !important;
    }
