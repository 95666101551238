.landing_page_table tr td div div {
    min-width: 125px !important;
    max-width: 125px !important;
    height: 60px !important;
}

.landing_page_table {
    margin-left: auto;
    margin-right: auto;
}

    .landing_page_table tr td {
        padding: 20px !important;
    }

.col-md-4 {
    max-width: 250px !important;
}

.continue_search_landing {
    display: table-cell !important;
    color: #fff !important;
    font-size: 14pt !important;
    padding: 10px 10px !important;
    border-radius: 8px !important;
    background: #ef2859 !important;
    cursor: pointer !important;
    border: 2px solid #ef2859;
    text-align: left !important;
    line-height: 18px !important;
    vertical-align: middle;
    min-width: 200px !important;
    max-width: 200px !important;
    /*height: 65px !important;*/
    margin-top: 25px !important;
    font-weight: 400;
    white-space: nowrap;
    font-family: 'Brown Bold';
}

.add_to_track {
    display: block !important;
    color: #fff !important;
    font-size: 20px !important;
    padding: 14px 0px !important;
    border-radius: 15px !important;
    background: #ef2859 !important;
    cursor: pointer !important;
    border: unset !important;
    text-align: center !important;
    line-height: 18px !important;
    vertical-align: middle;
    min-width: 160px !important;
    max-width: 160px !important;
    height: 65px !important;
    margin-top: 0px !important;
    font-weight: bold;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 15px;
}

.padded {
    padding-top: 20px !important;
}

.continue_search_landing:active, .continue_search_landing:hover {
    /*color: #ef2859 !important;
    background: #454d55 !important;
    border-color: #ef2859 !important;
    border-style: solid !important;
    border: solid !important;*/
    background: #363636 !important;
}

h3 {
    color: #454d55;
}

.landing_page_modal {
    position: absolute;
    margin-top: 30px;
    cursor: pointer !important;
    /* z-index: 99; */
    margin-left: 168px;
}

.modal-title-landing {
    font-size: 2rem !important;
}

.card-header {
    background: #ef2859;
    border: 2px solid #ef2859;
}

    .card-header:active, .card-header:hover {
        background: #363636;
        border: 2px solid #363636;
    }

.btn-link, .btn-link:hover, .btn-link:active, .btn-link:visited {
    color: white;
    font-size: 14pt;
    line-height: 18px;
    font-family: 'Brown Bold';
    width: 100%;
    text-align: left;
    text-decoration: none !important;
}

.get_started_title {
    color: whitesmoke;
    max-width: 50vw;
    margin-left: auto;
    width: -webkit-fill-available;
}

.get_started_header {
    background: whitesmoke;
}

.instructions-photo a img {
    width: -webkit-fill-available;
    max-width: 80vw;
}

.modal-body p {
    /*font-size: xx-large;*/
}

.welcome-icon {
    width: 60px;
    padding: 5px;
}

.card-header-instructions {
    margin-bottom: 10px !important;
    padding: unset !important;
}

    .card-header-instructions h5 .btn {
        padding: unset !important;
    }

.calibrate_intake_values {
    color: black !important;
    font-weight: bold;
}

.card-header-track, .card-header-eat {
    padding: 10px !important;
}

.instructions-photo img {
    cursor: pointer;
}
