iframe {
    /*position: fixed;*/
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
    height: 800px;
}

.calibrate-background {
    background: white !important;
}
