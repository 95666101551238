/* screen 2 css*/
.screen2_inner {
    max-width: 420px;
    margin: 70px auto 70px;
    text-align: center;
}

    .screen2_inner h1 {
    }

.home_top {
    margin: 100px 0 100px;
}

.home_bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
    left: 0px
}

.screen2_inner .btngroup {
    max-width: 340px;
    margin: 0 auto;
}

    .screen2_inner .btngroup .btntype1 {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

.screen2_inner .btm_btns a.btntype2 {
    width: calc(50% - 5px);
}
/*.screen2_inner .btm_btns a.btntype2:first-child {
    margin-right: 10px;
}*/
.screen2_inner .btm_btns {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-around;
    -moz-box-pack: space-around;
    justify-content: space-around;
    -ms-flex-pack: distribute;
}

.home_subtxt {
    font-size: 18px;
    line-height: 1.3;
    color: #fff;
    font-family: 'Brown Light';
    text-align: left;
}


@media only screen and (min-width: 481px) {
    .mobile_show {
        display: none;
    }
}


@media only screen and (max-width: 480px) {
    .screen2_inner h1 {
        text-align: left !important;
        line-height: 1.1;
        max-width: 300px;
    }

        .screen2_inner h1::after {
            margin-left: 0;
        }

    .screen2_inner {
        max-width: 300px;
        margin: 50px auto;
    }

    .home_top {
        margin: 50px 0 48px;
    }

    .screen2_inner .btm_btns {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

        .screen2_inner .btm_btns a.btntype2 {
            width: 175px;
        }

            .screen2_inner .btm_btns a.btntype2:first-child {
                margin-bottom: 20px;
            }

    h1 {
        font-size: 2.5rem !important;
    }

    .display-4 {
        font-size: 2.5rem !important;
    }
}

@media only screen and (max-width: 370px) {
    .screen2_inner img {
        max-width: 250px;
    }

    .screen2_inner h1 {
        font-size: 33px;
        max-width: 240px;
    }

    .home_subtxt {
        font-size: 16px;
    }

    .home_top {
        margin: 40px 0;
    }

    .screen2_inner {
        margin: 35px auto;
        max-width: 260px;
    }
}

.table-dark {
    background: #363636 !important;
    color: #ffffff !important;
    font-size: 21px;
    font-family: 'Brown Bold';
    cursor: pointer;
    /*display: inherit !important;*/
}
