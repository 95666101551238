.inspiredWrapper {
    margin-top: 70px;
    padding: 70px 0px 40px 0px;
    background-color: #ebf6fc;
}

.inspiredWrapper .card{
    margin-bottom: 30px;
    border: none;
}

.inspiredWrapper .card h4{
    padding-top: 10px;
    margin-bottom: 5px;
    color: #000;
    line-height: 29px;
    font-weight: 500;
}

.inspiredWrapper .card .card-body{
    position: relative;
}

.inspiredWrapper .travelImg{
    width: 100%;
    max-height: 220px;
    object-fit: cover;
}

.inspiredWrapper .thumbnailImg{
    position: absolute;
    top: -55px;
    left: 15px;
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0px 0px 10px #929292;
}

.inspiredBox {
    max-width: 69vw;
    margin: auto;
}

.inspiredBox .card a{
    display: block;
    white-space: break-spaces !important;
}




@media only screen and (min-width: 992px){

}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .dashboard_banner div h2 {
        font-size: 60px;
        line-height: 70px;
    }

    .goToBtn {
        font-size: 24px;
        padding: 18px 70px;
    }

    .dashboard_banner div p {
        font-size: 24px;
    }

    .preferences_wrapper h3{
        font-size: 26px;
        line-height: 36px;
    }

}

@media only screen and (min-width:768px){
.dashboard_page h1 br {
    display: none;
}
}


@media only screen and (max-width:767px){
    .continue_search_result {
        margin-top: 0;
    }
    .inner_page_main.dashboard_page .container {
        max-width: 540px;
        padding: 40px 0;
    }
    .inner_page_main.dashboard_page h1 {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    
    .dashboard_banner{
        min-height: 400px;
        background-size: cover;
        text-align: center;
    }
    .dashboard_banner div h2{
        font-size: 36px;
        line-height: 50px;
        text-align: center;
    }
    .goToBtn {
        font-size: 18px;
        margin-top: 30px;
        padding: 22px 60px;
    }
    .preferences_wrapper h3{
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .preferences_box {
        flex-direction: column;
        padding: 0px 30px;
    }
    .preferences_box .single_entity{
        max-width: 100%;
        width: 100%;
        padding: 40px 0 28px;
        margin-bottom: 30px;
    }
    .entity_count {
        width: 67px;
        height: 67px;
        font-size: 30px;
        line-height: 67px;
        left: -10px;
    }   
    
    .inspiredBox{
        max-width: 90vw;
    }
}

@media only screen and (max-width:575px){

}


@media only screen and (max-width:480px){
.inner_page_innersec h1 {
    font-size: 30px !important;
}
.inner_page_innersec h1::after {
    margin: 8px 0;
}
.inner_page_innersec {
    max-width: 300px;
}
.entitys_sec .col-sm-4 {
    width: 50%;
    margin-bottom: 30px;
}
}

@media only screen and (max-width:370px){
.inner_page_innersec h1 {
    font-size: 31px;
}
.entitys_sec .col-sm-4 {
    padding: 0 10px;
    margin-bottom: 20px;
}
.entitys_sec .row {
    margin: 0 -10px;
}
.inner_page_innersec {
    max-width: 270px;
}
}

.fa-video-camera {
    color: #454d55 !important;
    cursor: pointer !important;
}

.fa-video-camera.getting-started {
    color: #ef2859 !important;
    cursor: pointer !important;
    float: unset;
}

.fa-video-camera:active, .fa-video-camera:hover {
    color: #454d55 !important;
}