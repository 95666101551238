.logoWrapper{
    background-color: #fff;
    padding: 15px 0;
}
.logoWrapper .logo{
    width: 100%;
    max-width: 180px;
    margin-right: 30px;
}

@media only screen and (max-width:767px){

    .logoWrapper{
        text-align: center;
    }
    .logoWrapper .logo{
        /*max-width: 120px;*/
        margin-right: 15px;
    }
}