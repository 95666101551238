.page_leftsidebar button.btntype3 {
    max-width: 300px;
    width: 100%;
    border-radius: 2px;
    text-align: center;
    margin: 39px auto;
    display: block;
}

.custom-filters-icon-container {
    text-align: center;
}

    .custom-filters-icon-container h5 {
        font-weight: bold;
        margin-top: 10px;
    }

.custom-filters-icon {
    display: inline-block;
    max-width: 150px;
}

.singledish_full .singledish_top .singledish_topupper > img {
    max-height: 300px;
    width: 100%;
    display: block;
    margin: 0px auto;
    object-fit: contain;
}

.singledish_full .singledish_top .singledish_topupper {
    position: relative;
}

    .singledish_full .singledish_top .singledish_topupper h2 {
        color: #182026;
        width: 100%;
        margin-top: 24px;
        align-items: center;
        font-family: 'Montserrat-Light' !important;
        font-size: 40px;
        line-height: 48px;
    }

    .singledish_full .singledish_top .singledish_topupper .favrout_sec {
        position: absolute;
        top: 25px;
        right: 50px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: rgba(0,0,0,.3);
        border-radius: 50%;
        text-align: center;
    }

        .singledish_full .singledish_top .singledish_topupper .favrout_sec img {
            width: 25px;
        }

.singledish_full {
    background: #fff;
    box-shadow: 0px 8px 32px rgba(0,0,0,.1);
    padding: 50px 40px;
}

.singledish_label {
    padding: 7px 22px 7px 22px;
    display: inline-block;
    border-radius: 50px;
    font-size: 14px;
    color: #fff;
    font-family: 'Myriad Pro';
    background: red;
    background: #00a651;
    margin-bottom: 17px;
}

.singledish_full .singledish_top .singledish_toplover h3 {
    font-family: 'Montserrat-Light' !important;
    font-size: 15px;
    color: #606161;
    text-align: center;
    line-height: 22px;
    max-width: 80%;
    margin: auto;
    margin-bottom: 0px;
}

    .singledish_full .singledish_top .singledish_toplover h3 p {
        color: #606161;
        font-size: 15px;
        max-width: 85%;
        margin: auto;
    }

.singledish_full .singledish_top .personalized_order_sec h4 {
    font-size: 18px;
    margin-bottom: 15px;
}

.singledish_full .singledish_top .personalized_orderinner > div,
#MenuItemModalBody .mbPill > .pill, .mbPill > .pill {
    padding: 6px 16px 6px 22px;
    margin: 5px 5px 5px 0;
    display: inline-block;
    border-radius: 50px;
    font-size: 14px;
    color: #fff;
    font-family: 'Myriad Pro';
}

    .singledish_full .singledish_top .personalized_orderinner > div img {
        margin-left: 14px;
        cursor: pointer;
    }

.singledish_label i.fa {
    font-size: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    color: #e4e4e4;
}

.allergens_MM span {
    background: #ef2859 !important;
    padding: 6px 16px 8px 15px !important;
    margin: 0px 9px 0px 0px !important;
    line-height: 1;
    border: none;
    letter-spacing: 1px;
    color: #fff;
    border-radius: 50px;
    white-space: nowrap;
}

.padsections .personalized_order_sec {
    margin: 0px 0px;
}

.padsections h3 {
    font-size: 18px;
    margin: 0;
}

.padsections input[type=checkbox], input[type=radio] {
    margin: 0px 5px 6px 0px;
}

.padsections .col-sm-6.col-xs-12 {
    display: inline-block;
}

.padsections .allergens_MM {
    /*margin-top: 15px;*/
}

    .padsections .allergens_MM h4 {
        margin-top: 20px;
    }

#MenuItemModalBody .panel-body input {
    margin-left: 15px;
}

#MenuItemModalBody .panel-body {
    margin-bottom: 11px;
}

.allergens_page_sec .allergens_single label input:checked + .allergens_singleinner .allergens_singleinner_img {
    background: #FBB800;
    box-shadow: 1px 5px 33px rgba(0,0,0,.35);
}

.allergens_page_sec.text-center.row p {
    margin-bottom: 0px;
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    color: #606161;
}

.singledish_full .singledish_top .personalized_orderinner > div, #MenuItemModalBody .mbPill > .pill {
    margin: 5px 5px 8px 0px;
}

.pad001 {
    padding: 0px !important;
}

.m-topid {
    margin-top: 25px;
}

.descriptionText {
    position: relative;
}

.centerDividerLine {
    max-width: 110px;
    height: 4px;
    margin: 30px auto;
    background-color: #ef4b80;
}

@media only screen and (max-width: 1199px) {
    .singledish_full .singledish_top .singledish_topupper h2 {
        font-size: 30px;
    }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (max-width: 767px) {
    #dishaccordion .card .card-header button.btn.btn-link {
        font-size: 19px;
    }

        #dishaccordion .card .card-header button.btn.btn-link:after {
            width: 11px;
            height: 11px;
            border-width: 2px;
            top: 31px;
        }

    #dishaccordion .card {
        border-bottom: 1px solid #eeeeee;
    }

    #dishaccordion .submit_btn {
        padding: 35px 10px;
    }

    .singledish_full .singledish_top .singledish_topupper h2 {
        margin: 20px auto 0;
        color: #333;
        font-size: 24px;
        line-height: 1.3;
        position: relative;
        bottom: 0;
    }

        .singledish_full .singledish_top .singledish_topupper h2 span {
            max-width: 257px;
            display: block;
            margin: 0 auto;
        }

        .singledish_full .singledish_top .singledish_topupper h2:after {
            content: '';
            display: block;
            width: 50px;
            height: 5px;
            background: #ef2859;
            margin: 11px auto 0;
        }

    .singledish_label {
        display: none;
    }

    .singledish_page .page_rightsidebar {
        padding: 30px 15px;
    }

    #dishaccordion {
        border-top: 1px solid #eeeeee;
    }

    .singledish_full {
        margin-bottom: 0%;
    }
}

@media (min-width: 576px) {
    .col-sm-4.allergens_single {
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        max-width: 33%;
    }
}

@media only screen and (max-width: 480px) {
    .singledish_page .page_rightsidebar {
        padding: 0 0px 15px;
    }

    .singledish_full .singledish_top .singledish_topupper h2 {
        padding-top: 34px;
        top: -38px;
        background: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-bottom: -33px;
    }

    .singledish_full .singledish_top .singledish_topupper > img {
        width: calc(100% + 30px);
        max-width: initial;
        margin-left: -15px;
    }

    .singledish_full .singledish_top .singledish_toplover h3 {
        font-size: 16px;
        line-height: 1.5;
    }

    .singledish_full .singledish_top .singledish_toplover {
        padding-bottom: 45px;
    }
}

#MenuItemModalBody .allergens_MM span.pill {
    display: inline-block;
    background: none;
    padding: 0;
}

.pill .allergens_single {
    text-align: center;
    margin-bottom: 42px;
}

    .pill .allergens_single label {
        display: inline-block;
        cursor: pointer;
    }

.allergens_pills {
    width: 50px !important;
    height: 50px !important;
    font-size: 15px !important;
}

.pill .allergens_single label .allergens_singleinner_img {
    width: 120px;
    height: 120px;
    background: #ef2859;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    margin-bottom: 19px;
    position: relative;
}

    .pill .allergens_single label .allergens_singleinner_img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

.pill .allergens_single label input {
    display: none;
}

.pill .allergens_single label h4 {
    color: #ef2859;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Myriad Pro';
}

.pill .allergens_single label .allergens_singleinner_img img.allergens_img2 {
    display: none;
}

.pill {
    padding: 70px 0 50px;
}

    .pill .allergens_single label + .allergens_singleinner .allergens_singleinner_img {
        background: #ef2859;
        box-shadow: 1px 5px 33px rgba(0,0,0,.35);
    }

        .pill .allergens_single label + .allergens_singleinner .allergens_singleinner_img img.allergens_img1 {
            display: none;
        }

        .pill .allergens_single label + .allergens_singleinner .allergens_singleinner_img img.allergens_img2 {
            display: block;
        }

    .pill .allergens_single label + .allergens_singleinner h4 {
        color: #ef2859;
    }

/*.allergens_page_sec .allergens_single{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}*/

.allergens_page_sec .allergens_single label h4 {
    font-size: 15px !important;
}

.allergens_img1 {
    max-width: 30% !important;
}

.mymenu-label {
    margin-left: 5px;
    margin-top: 0px;
    position: absolute;
}

input[type="checkbox"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0px;
    border: 3px solid #dcdbdb;
    background-color: #dcdbdb;
}

input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0px;
    background-clip: content-box;
    border: 2px solid #dcdbdb;
    background-color: #dcdbdb;
    border-radius: 50%;
}

    /* appearance for checked radiobutton */
    input[type="radio"]:checked, input[type="checkbox"]:checked {
        background-color: #ef2859;
    }

/* optional styles, I'm using this for centering radiobuttons */
.flex {
    display: flex;
    align-items: center;
}

#dishaccordion .allergens_page_sec {
    padding: 6px 0;
    margin: 0 auto;
    max-width: 90%;
}

.padsections .allergens_MM h4 {
    width: 100%;
    text-align: left;
}

.padsections .allergens_singleinner h4 {
    width: 100%;
    text-align: center;
}

.personalized_order_sec_text {
    font-size: 10px;
}

.allergens_cursor {
    cursor: default !important;
}

    .allergens_cursor label {
        cursor: default !important;
    }

.nutritional.OOR {
    /*color: red !important;*/
    font-size: small !important;
}

.warning {
    color: red !important;
}

.col-sm-1 {
    max-width: inherit;
}

.back_btn {
    left: 20px;
    top: 20px;
    line-height: 36px;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0,0,0,.5);
    color: #ccc;
    text-align: center;
    /*line-height: 40px;*/
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
}

.nutrients_label {
    background-color: #f7f9f9;
    font-family: 'Montserrat-Light' !important;
    font-size: 18px !important;
    padding: 0.75rem;
    margin: -15px -10px 13px -10px !important;
}

.nutrients_value {
    font-family: 'Montserrat-Light' !important;
    font-size: 16px !important;
    color: #454d55 !important;
    background: transparent !important;
    margin: 0 !important;
    line-height: 0px !important;
}

.bp3-toast {
    position: fixed !important;
    top: 20px;
    right: 0;
    left: 0;
    margin: auto;
}

#trackAlertMessage {
    margin-bottom: 0;
    line-height: 26px;
}

    #trackAlertMessage span {
        color: #f13161;
    }

#trackAlert .modal-dialog {
    max-width: 550px;
    top: 24%;
}

#trackAlert .modal-body {
    padding: 8px 20px;
}

#trackAlert .modal-header, #trackAlert .modal-footer {
    border-bottom: none;
    border-top: none;
    padding: 15px 20px;
}


@media only screen and (max-width: 767px) {
    .allergens_page_sec .allergens_single {
        margin-bottom: 30px;
    }

    .singledish_full {
        padding: 0px 15px;
    }

    .table td, .table th {
        padding: .5rem;
    }

    .nutrients_label {
        font-size: 15px !important;
    }

    .nutrients_value {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 480px) {
    .allergens_page_sec .allergens_single {
        width: 50%;
    }

        .allergens_page_sec .allergens_single label .allergens_singleinner_img {
            width: 100px;
            height: 100px;
        }

        .allergens_page_sec .allergens_single label .allergens_singleinner_img {
            margin-bottom: 15px;
        }

        .allergens_page_sec .allergens_single label h4 {
            font-size: 20px;
            text-align: center;
        }

    .padsections .allergens_MM h4 {
        width: 100%;
        text-align: left;
    }
}
