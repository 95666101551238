.allergens_page .entity_page_boxtop {
    background-color: #FBB800;
    background-image: url('/public/static/img/allergensbg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
}

.allergens_page_sec .allergens_single {
    text-align: center;
    /*margin-bottom: 42px;*/
}

    .allergens_page_sec .allergens_single label {
        display: inline-block;
        cursor: pointer;
    }

        .allergens_page_sec .allergens_single label .allergens_singleinner_img {
            width: 120px;
            height: 120px;
            background: #d5d5d5;
            border-radius: 50%;
            display: block;
            /*margin: 0 auto;
    margin-bottom: 19px;*/
            position: relative;
        }

            .allergens_page_sec .allergens_single label .allergens_singleinner_img img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

        .allergens_page_sec .allergens_single label input {
            display: none;
        }

        .allergens_page_sec .allergens_single label h4 {
            color: #615656;
            font-size: 24px;
            font-weight: 400;
            font-family: 'Myriad Pro';
        }

        .allergens_page_sec .allergens_single label .allergens_singleinner_img img.allergens_img2 {
            display: none;
        }

.allergens_page_sec {
    padding: 70px 0 50px;
}

    .allergens_page_sec .allergens_single label input:checked + .allergens_singleinner .allergens_singleinner_img {
        background: #FBB800;
        box-shadow: 1px 5px 33px rgba(0,0,0,.35);
    }

        .allergens_page_sec .allergens_single label input:checked + .allergens_singleinner .allergens_singleinner_img img.allergens_img1 {
            display: none;
        }

        .allergens_page_sec .allergens_single label input:checked + .allergens_singleinner .allergens_singleinner_img img.allergens_img2 {
            display: block;
        }

    .allergens_page_sec .allergens_single label input:checked + .allergens_singleinner h4 {
        color: #FBB800;
    }

    .allergens_page_sec .submit_btn {
        width: 100%;
        text-align: center;
        margin-top: 26px;
    }

        .allergens_page_sec .submit_btn .btntype3 {
            border-radius: 4px;
            max-width: 321px;
            width: 100%;
        }

.allergens_agree label {
    cursor: pointer;
}

    .allergens_agree label input {
        display: none;
    }

.allergens_agree span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 33px;
    line-height: 2;
    color: #656e82;
    font-size: 11px;
    font-family: 'Brown Light';
    display: block;
}

    .allergens_agree span:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #b5b5b5;
        border-radius: 2px;
        background: #f2f2f2;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 0px;
    }

    .allergens_agree span:after {
        content: '';
        display: none;
        width: 22px;
        height: 22px;
        background: url(/public/static/img/check.png);
        background-repeat: no-repeat;
        position: absolute;
        left: 2px;
        top: -3px;
    }

.allergens_agree label input[type="checkbox"]:checked + span:after {
    display: block;
}

.allergens_agree
label {
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 0;
    vertical-align: middle;
    height: 21px;
}

.allergens_agree p {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    color: #656e82;
    font-size: 14px;
    font-family: 'Brown Light';
}

.allergens_agree {
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 23px 10px;
    box-shadow: 0px 0px 20px rgba(0,0,0,.2);
    background: #f8f8f8;
}

    .allergens_agree p a {
        color: #ef2859;
        font-family: 'Brown Regular Regular';
    }

        .allergens_agree p a:hover {
            color: #000;
        }


@media only screen and (max-width: 767px) {
    .allergens_page .col-md-12 {
        padding: 0 !important;
    }

    .allergens_page .inner_page_innersec {
        max-width: 100%;
    }

    .allergens_page .entity_page_boxbtm {
        padding: 0;
        border-radius: 0 !important;
    }

    .allergens_page .entity_page_boxbtminner {
        border-radius: 0;
        padding-top: 30px;
        padding-bottom: 0px;
    }

    .allergens_page .entity_page_boxtop {
        border-radius: 0;
        background-size: 228px;
    }

    .allergens_page h1 {
        display: none !important;
    }

    .allergens_page .entity_page_boxtop {
        background-size: 253px;
    }

    .allergens_page_sec .allergens_single {
        margin-bottom: 30px;
    }

    .allergens_page .entity_page_boxbtminner .allergens_page_sec {
        padding-bottom: 29px;
    }

    .allergens_page_sec .submit_btn {
        margin-top: 0;
    }
}

.terms_alert {
    color: red;
    font-weight: bold;
}


@media only screen and (max-width: 480px) {
    .allergens_page_sec .allergens_single {
        width: 50%;
    }

        .allergens_page_sec .allergens_single label .allergens_singleinner_img {
            width: 100px;
            height: 100px;
        }

        .allergens_page_sec .allergens_single label .allergens_singleinner_img {
            margin-bottom: 15px;
        }

        .allergens_page_sec .allergens_single label h4 {
            font-size: 20px;
        }
}

@media only screen and (max-width: 370px) {
    .allergens_agree {
        padding: 24px 5px;
    }

        .allergens_agree p {
            font-size: 12px;
        }
}

/*.allergens_singleinner {
    margin-bottom: 40px;
}*/

.allergens_pills {
    margin: 0 auto;
}

.made-without p{
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
}

.made-without-title{
    color: #000;
    text-decoration: underline;
    font-size: 18px;
    line-height: 26px;
}

.made-without ol li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
}

.made-without b, .made-without strong{
    color: #000;
}

.made-without span{
    color: red;
    font-size: 20px;
    position: relative;
    top: 1px;
}

.made-without tbody td{
    line-height: 24px;
}