.microsoft-login-wraper{
    height: 84vh;
    display: flex;
    align-items: center;
}

.loginBtn{
    display: inline-block;
    background: #7FBB0A;
    color: #fff;
    padding: 20px 15px;
    border-radius: 90px;
    text-transform: uppercase;
    width: 100%;
    margin: 0px 0px 30px 0;
    cursor: pointer;
    max-width: 300px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
}

@media only screen and (max-width:767px){
    .loginBtn{
        width: 75%;
        font-size: 16px;
    }
}